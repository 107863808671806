import {Form, Input, InputNumber, Table, Popconfirm} from 'antd';
import React, {useState} from 'react';
import intl from 'react-intl-universal';

const TableCell = ({
                       editing,
                       dataIndex,
                       title,
                       inputType,
                       record,
                       index,
                       children,
                       ...restProps
                   }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `${intl.get('CommonTable')['请输入']} ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const CommonTable = (props) => {
    const columns = [...props.columns] // 表格列的配置描述
    const isEdit = props.isEdit  // 是否需要在表格中编辑内容
    const editIndex = props.editIndex // 编辑功能列是动态插入的,这个是插入的下标位置
    const [form] = Form.useForm();
    const [data, setData] = useState(props.list);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;


    if (isEdit && !columns.find(vm => vm.title === intl.get('CommonTable')['操作'])) {
        columns.splice(columns.length + editIndex, 0, {
            title: intl.get('CommonTable')['操作'],
            dataIndex: 'operation',
            width: 160,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <div className='flex-ac' style={{color:'#1890ff',cursor: 'pointer'}}>
                <div onClick={() => save(record.id)} style={{marginRight: 8}}>{intl.get('CommonTable')['保存']}</div>
                <div onClick={cancel} >{intl.get('CommonTable')['取消']}</div>
            </div>
          </span>
                ) : (
                    <div className='flex'>
                        <div className='flex-ac' onClick={() => edit(record)} style={{
                            cursor: 'pointer',
                            marginRight: '20px',
                            color: editingKey ? 'rgba(0, 0, 0, .25)' : '#F7385B'
                        }}>
                            <img
                                src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/bianji.png'
                                style={{
                                    width: 14,
                                    height: 14,
                                    marginRight: 5,
                                    cursor: 'pointer'
                                }}/>{intl.get('CommonTable')['修改']}
                        </div>
                        <Popconfirm
                            title={intl.get('CommonTable')['是否删除该条数据']}
                            onConfirm={() => {deleteData(record)}}
                            okText={intl.get('CommonTable')['确认']}
                            cancelText={intl.get('CommonTable')['取消']}
                        >
                            <div className='flex-ac'
                                 style={{cursor: 'pointer', color: editingKey ? 'rgba(0, 0, 0, .25)' : '#F7385B'}}>
                                <img
                                    src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/shanchu.png'
                                    style={{
                                        width: 14,
                                        height: 14,
                                        marginRight: 5,
                                        cursor: 'pointer'
                                    }}/>
                                <div style={{flexShrink: '0'}}>{intl.get('CommonTable')['删除']}</div>
                            </div>
                        </Popconfirm>
                    </div>
                );
            },
        },)
    }

    const edit = (record) => {
        form.setFieldsValue({
            contactName: '',
            phone: '',
            postCode: '',
            address: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const deleteData = record => {
        props.deleteData(record.id)
    }

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                setEditingKey('');
                props.saveData({...item, ...row})
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: TableCell,
                    },
                }}
                colSpan={0}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

export default CommonTable;