import React, {useState, useEffect, useRef} from 'react'
import { Image, Input, Checkbox, Button, Spin, notification, message } from 'antd';
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import Language from './Language';
import { NavLink } from 'react-router-dom';
import withRouter from '../utils/withRouter'
import intl from 'react-intl-universal';
import store from '../utils/redux/index'

const Header = (props) => {
    // console.log(props)
    const [isBack, setBack] = useState(false)
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [userInfo, setUserInfo] = useState()
    const [enterprise, setEnterprise] = useState()
    useEffect(()=>{


        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }

    },[])
    const [isWeb, setWidths] = useState(false)
    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        setWidths(width > 750)
    }

    const linkTitle = [
        {title: '品购星选', url:'https://shop.brandgogo.com', state: '/'},
        {title: '榜单', url:'https://shop.brandgogo.com/homeGoodsRanking', state: '/rangKing'},
        // {title: intl.get('Header').SelectionHall, url:'/index/bloggerRanking', state: '/rangKing'},
        {title: '小店', url:'https://shop.brandgogo.com/homeSmallShopRanking', state: '/u'},
        {title: '品牌', url:'/index/workbench', state: '/t'},
        // {title: intl.get('Workbench').HelpCenter, url:'/e'},
    ]
    const [pageIndex, changeIndex] = useState(-1)

    const goto =(item, index)=>{
        if(item.url.includes('http')) {
            window.open(item.url)
            return
        }
        changeIndex(index)
        // if(index==0){
        //     props.navigate('/')
        // }else if(localStorage.getItem('token') && localStorage.getItem('token')!='null'){
            props.navigate(item.url, {state: item.state})
        // }else{
        //     message.info(intl.get('Header')['请先登录!'])
        //     props.navigate('/login')
        // }
    }
    const gotoW =()=>{
        if(localStorage.getItem('token') && localStorage.getItem('token')!='null'){
            props.navigate('/index/bloggerRanking', {state: '/rangKing'})
        }else{
            message.info(intl.get('Header')['请先登录!'])
            props.navigate('/login')
        }
    }

    return (
        <div style={{width:'100%', height:'66px', letterSpacing:'0.5px', userSelect:'none'}}>

            <div id='header' style={{
                minWidth:'1400px', width: '100%', height: '66px', display: 'flex', alignItems: 'center', position: 'fixed', zIndex: 2,
                top: 0, left: 0, backgroundColor: '#111111',
                // boxShadow: '0px 0px 10px 0px #F8F8F8', color:'#FFF',
                justifyContent:'space-between',
            }}>
                <div style={{width:'1400px', display:'flex', alignItems:'center', justifyContent:'space-between',
                    fontWeight:'bold', margin:'0 auto'}}>
                    <div style={{width:'850px', display:'flex', alignItems:'center', justifyContent:'space-between',
                    fontWeight:'bold'}}>
                        <div style={{ width: '210px', textAlign:'center'}}>
                            <img onClick={()=>goto(0, 0)} style={{width:'100%', marginLeft: isWeb ? 0 : '-80px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                        </div>
                        {
                            linkTitle?.map((item, index)=>{
                                return <div key={index} style={{position:'relative'}}>
                                    <div to={item.url} onClick={()=>goto(item, index)}
                                        style={{ color: pageIndex == index ? '#F7385B' : '#FFF', cursor:'pointer' }}>
                                            {item.title}
                                    </div>
                                    <div to={item.url} onClick={()=>goto(item, index)}
                                        style={{width:'100%', height:'3px', position:'absolute', bottom: '-22px', left:0,
                                        backgroundColor: pageIndex == index?'#F7385B':'' , cursor:'pointer'}}>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {/*<div onClick={()=>props.navigate('/loginTab')} style={{width:'108px', height:'34px',*/}
                    {/*    border:'1px solid #F7F7F7',*/}
                    {/*    borderRadius:'6px', color: '#FFFFFF', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>*/}
                    {/*    登录/注册*/}
                    {/*</div>*/}
                </div>


                 <Language />

            </div>


        </div>
    )
}

export default withRouter(Header)
