import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { Input, Upload, Image, Button, Radio, Space, message, Cascader, DatePicker, Select, Modal } from 'antd';
import {baseUrl} from '../../service/request'
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import store from '../../utils/redux/index'
import moment from 'moment';
import BloggerSigning from '../../components/Login/BloggerSigning'
const {Option} = Select

const Perfect = (props)=>{

    useEffect(()=>{
        console.log(props)
        getusers()
    },[])

    const [users,setUserInfo]= useState()
    const getusers = ()=>{
        Api.infoP().then(res => {
            if (res.code == 200) {
                setUserInfo(res.data)
                store.dispatch({ type: 'userInfo', value: res.data })
            }
        })
    }

    const [isSuccess, setIsSuccess] = useState(false)

    return <div style={{width:'100%', background:'#F6F6F6', paddingTop:'86px'}}>
        <div style={{width:'100%', height:'66px', letterSpacing:'0.5px', userSelect:'none', backgroundColor: '#111', boxShadow: '0px 0px 10px 0px #F8F8F8',
            position: 'fixed', zIndex: 2, top: 0, left: 0, color:'#FFF', }}>

            <div style={{
                width: '1400px', height: '66px', display: 'flex', alignItems: 'center', margin:'0 auto',
                justifyContent:'space-between', padding:'0 10px'
            }}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <img onClick={()=>window.open('https://brandgogo.com/')} style={{width:'190px', cursor:'pointer'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                    <span style={{marginLeft:'78px'}}>红人管理中心</span>
                </div>
                {/*<div onClick={()=>props.navigate('/login')} style={{width:'98px', height:'34px', */}
                {/*    border:'1px solid #F7F7F7',*/}
                {/*    borderRadius:'6px', color: '#FFFFFF', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>*/}
                {/*    卖家课堂*/}
                {/*</div>*/}

            </div>
        </div>

        <div style={{width: '1400px', minHeight:'calc(100vh - 86px)', background:'#FFF',
        margin:'0 auto'}}>
            {/* <BloggerSigning users={{uid:'123123', email: '12312'}} isEmailLogin={'123123'} /> */}
            <BloggerSigning users={{uid:props.location.state.uid, email: props.location.state.phone}} isEmailLogin={props.location.state.phone} />

            <div style={{height:'100px'}}></div>
        </div>

        <Modal centered visible={isSuccess} footer={null} onCancel={e => { setIsSuccess(false) }}
            // getContainer={() => document.getElementById('Status1')}
            // bodyStyle={{
            //     padding: '0', backgroundColor: '#FFFFFF',
            //     width: 462, height: 466,
            // }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/stepsSuccess.png'} alt=""
                    style={{ width: '104px', marginTop: '40px' }} />
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop:'10px', marginBottom:'15px', lineHeight:'25px' }}>尊敬的{props.location.state?.phone}用户<br/>您的信息提交成功！</h3>
                <div style={{fontWeight:600, lineHeight:'24px'}}>我们将对资料进行审核，请保持电话畅通，<br/>工作人员将<span style={{color:'#F7385B'}}>在3-5个工作日</span>内与您联系！</div>
                <div style={{
                    width: '95px', height: '30px', borderRadius: '15px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '30px', color: '#FFF', margin: '38px auto',
                    background: '#F7385B', fontWeight: 500,
                }}
                onClick={()=>props.navigate('/')} >
                    {['去首页']}
                </div>
            </div>
        </Modal>
    </div>
}
export default withRouter(Perfect);
