import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import intl, { get } from 'react-intl-universal';
import { useNavigate } from 'react-router-dom'
import { Input, Select, Form, Button, Upload, message, notification, Image, Progress, Badge, Steps, Tooltip,
    Modal, Spin, List, Skeleton, Divider } from 'antd';
import { CloseCircleOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { getUserInfo, setUpUser, getInfoDetail } from '../../../service/https'
import * as Api from '../../../service/https'
import { baseUrl } from '../../../service/request'
import '../../../asset/css/Account.scss'
import store from '../../../utils/redux/index'
import Item from 'antd/lib/list/Item';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Column } from '@ant-design/plots';
import SampleDeliveryAddress from '../../../components/Personal/SampleDeliveryAddress'
const { Option } = Select;
const { Step } = Steps;

const InfluencerAccount = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const navigate = useNavigate();
    const [textJsonI, setTextJsonI] = useState(intl.get('InfluencerAccount'))
    const [textJson, setTextJson] = useState(intl.get('account'))
    const [textJsonNL, setTextJsonNL] = useState(intl.get('NewLogin'))
    const [lng, setLng] = useState(localStorage.getItem('locale'))
    const [params, setParams] = useState({})
    const [phone, setPhone] = useState('')
    const [userInfo, setUserInfo] = useState()
    const [nicknameStatus, setNicknameStatus] = useState(false)
    const [nickname, setNickname] = useState()
    const intervalRef = useRef(null);
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    useEffect(() => {
        if(tabIndex == 2){
            getNew1sList()
            getNews2List()
            getNews3List()
        }
    },[state.news?.unreadCount])

    useEffect(() => {
        getUserInfoFun()
        // getNewsTotal()
        return () => intervalRef ? clearInterval(intervalRef?.current) : ''
    }, [])

    const getUserInfoFun = () => {
        getInfoDetail().then(res => {
            if (res.code === 200) {
                setUserInfo(res.data)
                let objs = res.data.redAccountDetail
                objs.email = res.data.email
                setIsPassword(!res.data.password)
                setIsAddress(!res.data.redAccountDetail.recipientName)
                setInformation(res.data.redAccountDetail)
                setPhone(res.data.phone)
                store.dispatch({ type: 'userInfo', value: res.data })
            }
        })
    }

    const [unreadCount, setUnreadCount] = useState(0)
    const getNewsTotal = () => {
        Api.getNewsTotal().then(res => {
            if (res.code == 200) {
                setUnreadCount(res.data.unreadCount)
            }
        })
    }



    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    const [password, setPassword] = useState('')


    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };


    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(userInfo))
                    t.avatar = info.file.response.data
                    setUserInfo(t)
                    redSetup(5, info.file.response.data)
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };



    const logouts = () => {
        Api.logout().then(res => {
            if (res.code == 200) {
                localStorage.setItem("token", null)
                store.dispatch({ type: 'token', value: null })
                store.dispatch({ type: 'userInfo', value: null })
                props.navigate('/login')
                window.location.reload()
            }
        })
    }


    const [credit, setCredit] = useState()
    const [creditLog, setCreditLog] = useState([])
    const [news1List, setNews1List] = useState([])
    const [news2List, setNews2List] = useState([])
    const [news3List, setNews3List] = useState([])
    const tabList = [
        {icon:'shezhi', title: textJsonI['信息设置']},
        // {icon:'jifen', title: textJsonI['信用积分']},
        // {icon:'xiaoxitongzhi', title: textJsonI['消息通知']},
    ]
    const [tabIndex, setTabIndex] = useState(0)
    useEffect(()=>{
        getAllList()
    },[tabIndex])

    const getAllList = () => {
        if(tabIndex == 1){
            Api.getRedCredit().then(res=>{
                if(res.code == 200){
                    setCredit(res.data)
                }
            })
            Api.getCreditLog().then(res=>{
                if(res.code == 200){
                    setCreditLog(res.data)
                }
            })
        }
        if(tabIndex == 2){
            getNew1sList()
            getNews2List()
            getNews3List()
        }
    }

    const getNews3List = ()=>{
        Api.getNewsList({type: 3}).then(res=>{
            if(res.code == 200){
                res.data.informationList?.map(item=>{
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews3List(res.data)
            }
        })
    }
    const getNews2List = ()=>{
        Api.getNewsList({type: 2}).then(res=>{
            if(res.code == 200){
                res.data.informationList?.map(item=>{
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews2List(res.data)
            }
        })
    }
    const getNew1sList = ()=>{
        Api.getNewsList({type: 1}).then(res=>{
            if(res.code == 200){
                res.data.informationList?.map(item=>{
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews1List(res.data)
            }
        })
    }
    const informationSearch = e => {
        Api.informationSearch().then(res => {
            if (res.code === 200) {
                // setUnreadCount(res.data.unreadCount || 0)
                store.dispatch({ type: 'news', value: {unreadCount: res.data.unreadCount || 0} })
            }
        })
    }
    const changeChecked = (bools, index, type)=>{
        informationSearch()
        let v = JSON.parse(JSON.stringify(type==1?news1List:type==2?news2List:news3List))
        if(tab3Index == 0)v.informationList[index].isChecked = bools
        if(v.informationList[index].extraInfo){
            console.log(v.informationList[index].extraInfo)
            let obj = {
                target: v.informationList[index].extraInfo.targetCode,
                media: v.informationList[index].extraInfo.mediaCode,
                cooperationWay: v.informationList[index].extraInfo.cooperationWayCode,
                listId: v.informationList[index].extraInfo.listId,
                demandId: v.informationList[index].extraInfo.demandId,
            }
            // console.log(obj)
            // props.navigate('/index/videoDemandDetail', { state: obj })
            Api.marketBloggerDetail(obj).then(res=>{
                if(res.code == 200){
                    props.navigate('/index/videoDemandDetail', { state: obj })
                }else if(res.data && res.data.includes('未找到对应数据')){
                    getAllList()
                }
            })
        }
        if(v.informationList[index].readStatus === 2) {
            let data = {
                id: v.informationList[index].id,
                informationType: v.informationList[index].informationType,
                readStatus: 1
            }
            Api.updateStatus(data).then(res => {
                if(res.code == 200){
                    v.informationList[index].readStatus = 1
                    v.unreadCount -= 1
                    if(type == 1)setNews1List(v)
                    if(type == 2)setNews2List(v)
                    if(type == 3)setNews3List(v)
                }
            })
        }else{
            if(type == 1)setNews1List(v)
            if(type == 2)setNews2List(v)
            if(type == 3)setNews3List(v)
        }
    }


    const tab1List = [
        {icon:'info', title:textJsonI['信息设置']},
        {icon:'xuanpindaihuo', title:textJsonI['带货信息']},
        {icon:'zhuanzhang', title:textJsonI['收款信息']},
        {icon:'dizhi', title:textJsonI['样品收货地址']},
    ]
    const [tab1Index, setTab1Index] = useState(0)
    useEffect(()=>{
        if(tab1Index == 1 && (classList.length==0 || geolocation.length== 0)){
            getCargo()
            getCountry()
        }
        setIsPassword(false)
        setIsInformation(false)
        setIsCommerce(false)
        setIsCollection(false)
        setIsAddress(false)
    },[tab1Index])
    const tab2List = [
        {icon:'jifen', title:textJsonI['我的信用']},
        {icon:'baogao', title:textJsonI['信用积分规则']},
    ]
    const [tab2Index, setTab2Index] = useState(0)
    const tab3List = [
        {icon:'jifen', title:textJsonI['系统消息通知']},
        {icon:'baogao', title:textJsonI['商家合作申请']},
        {icon:'baogao', title:textJsonI['我的合作申请']},
    ]
    const [tab3Index, setTab3Index] = useState(0)
    useEffect(()=>{
        if(tabIndex == 2){
            if(tab3Index == 0) getNew1sList()
            if(tab3Index == 1) getNews2List()
            if(tab3Index == 2) getNews3List()
        }
    },[tab3Index])

    const [information, setInformation] = useState({})
    const changeInfo = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }




    const [classList, setClassList] = useState([]) 
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                setClassList(res.data)
            }
        })
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }

    const [icon, setIcon] = useState('')
    const [nationalityEn, setNationalityEn] = useState('')
    const [nationalId, setNationalityId] = useState('')
    const handleChange =(value, e)=> {
        // console.log(`selected ${value}`);
        changeInfo('nationality', value)
        setIcon(value == undefined?'':e.icon)
        setNationalityEn(value == undefined?'':e.enName)
        setNationalityId(value == undefined?'':e.id)
    }
    useEffect(()=>{
        changeInfo('nationalFlag', icon)
    },[icon])
    useEffect(()=>{
        changeInfo('nationalityEn', nationalityEn)
    },[information.nationalFlag])
    useEffect(()=>{
        changeInfo('nationalId', nationalId)
    },[information.nationalityEn])
    const [classId, setClassId] = useState('')
    const [cargoClassificationInterpretation, setCargoClassificationInterpretation] = useState('')
    const handleChanges =(value, e)=> {
        // console.log(`selected ${value}`);
        if(value.length < 4) {
            changeInfo('cargoClassificationInterpretationEn', value == undefined?[]:value)
            let idList = [], enLikst = []
            e.forEach(item => {
                idList.push(item.id)
                enLikst.push(item.name)
            })
            setClassId(idList.join(','))
            setCargoClassificationInterpretation(enLikst.join(','))
        }
    }
    useEffect(()=>{
        changeInfo('cargoClassification', classId)
    },[classId])
    useEffect(()=>{
        changeInfo('cargoClassificationInterpretation', cargoClassificationInterpretation)
    },[information.cargoClassification])



    const customDot = (dot, { status, index }) => (
        <div style={{width:'8px', height:'8px', background:'#FFF', border:'2px solid #F7385B', borderRadius:'4px'}}>
            
        </div>
    );


    
    const [loading, setLoading] = useState(false);
    const [isTixian, setTixian] = useState(false)  
    useEffect(()=>{
        if(isTixian)loadMoreData()
    },[isTixian])  
    const [totalTi, setTotalTi] = useState(0);
    const [dataTi, setDataTi] = useState([]);
    const [pageIndexTi, setPageIndexTi] = useState(1);
    const closeTixian = () => {
        setPageIndexTi(0)
        setTixian(false)
        setDataTi([])
    }
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndexTi, size: 10,
        }
        Api.getCreditDetail(obj).then(res => {
            console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotalTi(res.data.total)
                setDataTi(pageIndexTi == 1 ? res.data.records : [...dataTi, ...res.data.records]);
                setPageIndexTi(pageIndexTi + 1)
            }
        }).catch(() => {
            setLoading(false);
        });
    };





    const data = [
        {
          type: '',
          value: '350分',
        },
        {
          type: textJsonI['较差'],
          value: '550分',
        },
        {
          type: textJsonI['中等'],
          value: '600分',
        },
        {
          type: textJsonI['良好'],
          value: '650分',
        },
        {
          type: textJsonI['优秀'],
          value: '700分',
        },
        {
          type: textJsonI['极好'],
          value: '950分',
        },
    ];
    const paletteSemanticRed = '#FFEEF1';
    const brandColor = '#FFEEF1';
    const config = {
        data,
        xField: 'type',
        yField: 'value',
        seriesField: '',
        color: ({ type }) => {
          if (type === '10-30分' || type === '30+分') {
            return paletteSemanticRed;
          }
    
          return brandColor;
        },
        label: {
          content: (originData) => {
            const val = parseFloat(originData.value);
    
            if (val < 0.05) {
              return (val * 100).toFixed(1) + '%';
            }
          },
          offset: 10,
        },
        legend: false,
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
    };

    const [isPassword, setIsPassword] = useState(false)
    const [isInformation, setIsInformation] = useState(false)
    useEffect(()=>{
        if(isPassword) setIsInformation(false)
    },[isPassword])
    useEffect(()=>{
        if(isInformation) setIsPassword(false)
    },[isInformation])

    const [isCommerce, setIsCommerce] = useState(false)
    const [isCollection, setIsCollection] = useState(false)
    const [isAddress, setIsAddress] = useState(false)



    // 获取验证码
    const getEmailCode = ()=>{
        if(isCode && userInfo.email!='' && userInfo.email){
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getEmailCode({toEmail: userInfo.email}).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(()=>{
                        if(times == 0){
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times --)
                    },1000)
                    intervalRef.current = downTime
                }
            })
        }
    }

    const redSetup = (type, imgUrl = '')=>{
        let obj = [
            {
                redAccountSetupType: 1, email: information.email, verifyCode: codes, password: password
            },
            {
                redAccountSetupType: type, nickname: information.nickname, realName: information.realName,
                whatsApp: information.whatsApp
            },
            {
                redAccountSetupType: type, nationality: information.nationality, nationalId: information.nationalId,
                nationalityEn: information.nationalityEn, nationalFlag: information.nationalFlag,
                cargoClassification: information.cargoClassification, cargoClassificationInterpretation: information.cargoClassificationInterpretation,
                cargoClassificationInterpretationEn: information.cargoClassificationInterpretationEn.toString().split(',').join(','),
                videoQuotationDollar: information.videoQuotationDollar, liveBroadcastQuotationDollar: information.liveBroadcastQuotationDollar,
            },
            {
                redAccountSetupType: type, payoneerAccount: information.payoneerAccount, payoneerRealName: information.payoneerRealName
            },
            {
                redAccountSetupType: type, recipientName: information.recipientName, recipientPhone: information.recipientPhone,
                address: information.address, postalCode: information.postalCode
            },
            {
                redAccountSetupType: 1, avatarMedium: imgUrl
            },
        ]
        console.log(obj[type])
        Api.redSetup(obj[type]).then(res=>{
            if(res.code == 200){
                openNotificationWithIcon('success', '修改成功！')
                if(type == 0) {
                    setIsPassword(false)
                    setPassword('')
                    setCode('')
                }
                if(type == 1) setIsInformation(false)
                if(type == 2) setIsCommerce(false)
                if(type == 3) setIsCollection(false)
                if(type == 4) setIsAddress(false)
                getUserInfoFun()
            }
        })
    }

    const creditLevelList = ['', textJsonI['中等'],textJsonI['良好'],textJsonI['优秀'],textJsonI['极好']]

    

    return userInfo ? (
        <div id='Account'>
            <nav style={{ width: '100%', height:'200px', position: 'relative', fontWeight: 500}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/hNav.png'} alt=""
                    style={{ width: '100%', height:'100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent:'space-between', padding:'0 50px',
                }}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Image src={userInfo?.avatar || userInfo?.redAccountDetail?.avatarMedium || ''} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'}
                            style={{width:'94px', height:'94px', borderRadius:'47px', border:'4px solid #FFF'}}/>
                        <div style={{marginLeft:'27px'}}>
                            <div style={{fontSize:'24px', fontWeight:'bold',lineHeight:'24px'}}>{userInfo.redAccountDetail.nickname}</div>
                            <div style={{color:'#666666', fontWeight:500, marginTop:'2px'}}>
                                <span>TikTok: {userInfo.redAccountDetail.tiktok}</span>
                                {/* <span style={{marginLeft:'40px'}}>{textJsonI['信用']}: {textJsonI[userInfo.creditLevel]}</span> */}
                            </div>
                            <div style={{marginTop:'13px', display:'flex', flexWrap:'wrap'}}>
                                
                                {(lang=='zh'?userInfo?.redAccountDetail?.cargoClassificationInterpretation:userInfo?.redAccountDetail?.cargoClassificationInterpretationEn)?.split(',')?.map((val,index)=>{
                                    return val ? <span key={index} style={{padding:'4px 10px', border:'1px solid #F7385B', color:'#F7385B', marginRight: index<2?'5px':0,
                                        fontSize:'13px', borderRadius:'20px', opacity:0.75}}>{val}</span> : ''
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{width:'50%', display:'flex', justifyContent:'space-between', alignItems:'center', 
                        marginRight:'30px', textAlign:'center'}}>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight:'24px' }}>${userInfo.redAccountDetail.videoQuotationDollar}/{intl.get('NewLogin')['60s内']}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('Index')['视频单价']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight:'24px' }}>${userInfo.redAccountDetail.liveBroadcastQuotationDollar}/{intl.get('NewLogin')['1h内']}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('Index')['直播单价']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight:'24px' }}>{userInfo.videoNumber}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {textJsonI['待完成短视频']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight:'24px' }}>{userInfo.liveNumber}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {textJsonI['待完成直播']}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <div style={{width:'100%', height:'60px', background:'#FFF', display:'flex', alignItems:'center', letterSpacing:'1.2px'}}>
                {
                    tabList?.map((item,index)=>{
                        return <div onClick={()=>setTabIndex(index)} key={index} style={{height:'100%', display:'flex', alignItems:'center', 
                            borderBottom: tabIndex == index?'2px solid #F7385B':'2px solid #FFF', cursor:'pointer',
                            marginLeft:'90px', color: tabIndex == index ? '#F7385B':'#333'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/'+item.icon+(index==tabIndex?'s':'')+'.png'} 
                                style={{width: index==2?'18px':'20px', marginRight:'5px'}} />
                            {index==2? <Badge count={state.news?.unreadCount >= 0 ? state.news?.unreadCount : unreadCount} offset={[unreadCount>9?16:8, 0]} size="small">
                                <span style={{fontSize:'15px', fontWeight:'bold', color: tabIndex == index ? '#F7385B':'#333'}}>{item.title}</span>
                            </Badge> :<span style={{fontSize:'15px', fontWeight:'bold'}}>{item.title}</span>}
                        </div>
                    })
                }
            </div>
            
            <div id='InfluencerAccount' style={{ backgroundColor: '#fff', marginTop: '10px', display:'flex', minHeight:'600px'}}>
                <div style={{width:'224px', minWidth:'224px', background:'#F0F0F0', letterSpacing:'1.2px'}}>
                    {
                        tabIndex==0? tab1List?.map((item,index)=>{
                            return <div onClick={()=>setTab1Index(index)} key={index} style={{width:'100%', height:'110px', 
                            background:tab1Index == index ? '#FFF':'', cursor:'pointer',
                            color: tab1Index == index ? '#F7385B':'#333', textAlign:'center', paddingTop:'30px'}}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/'+item.icon+(index==tab1Index?'s':'')+'.png'} 
                                    style={{width: index==0?'28px':index==1?'32px':index==2?'33px':'28px', marginRight:'5px'}} />
                                <div style={{fontSize:'14px', fontWeight:'bold', marginTop:'5px'}}>{item.title}</div>
                            </div>
                        }) :
                        tabIndex==1? tab2List?.map((item,index)=>{
                            return <div onClick={()=>setTab2Index(index)} key={index} style={{width:'100%', height:'110px', 
                            background:tab2Index == index ? '#FFF':'', cursor:'pointer',
                            color: tab2Index == index ? '#F7385B':'#333', textAlign:'center', paddingTop:'30px'}}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/'+item.icon+(index==tab2Index?'s':'')+'.png'} 
                                    style={{width: index==0?'30px':'26px', marginRight:'5px'}} />
                                <div style={{fontSize:'14px', fontWeight:'bold', marginTop:'5px'}}>{item.title}</div>
                            </div>
                        }) : 
                        tabIndex==2? tab3List?.map((item,index)=>{
                            return <div onClick={()=>setTab3Index(index)} key={index} style={{width:'100%', height:'110px', 
                            background:tab3Index == index ? '#FFF':'', cursor:'pointer', lineHeight:'18px',
                            color: tab3Index == index ? '#F7385B':'#333', textAlign:'center', paddingTop:'35px'}}>
                                <div style={{fontSize:'14px', fontWeight:'bold'}}>{item.title}</div>
                                <div style={{fontSize:'14px', fontWeight:'bold'}}>（{index==0?news1List.unreadCount:index==1?news2List.unreadCount:news3List.unreadCount} {textJsonI['条未读']}）</div>
                            </div>
                        }) : ''
                    }
                    {tabIndex==0?<div style={{
                        width: '152px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                        color: '#666', margin: '49px auto', border: '1px solid #EEEEEE', fontWeight: 'blod',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', background:'#FFF', marginBottom:'60px',
                    }}
                        onClick={() => logouts()}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'} style={{ width: '16px', marginRight: '8px' }} alt="" />
                        {intl.get('ReleaseDemand')['退出账号登录']}
                    </div>:''}
                </div>
                {tabIndex == 0 ?
                // 信息设置
                <div>
                    {tab1Index == 0 ? 
                    // 基本信息
                    <div id='login' className='InfluencerAccountInfo' style={{width:'860px', paddingLeft:'50px', paddingTop:'50px', position:'relative'}}>
                        <div style={{paddingLeft:'40px', margin:'0 auto'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{'TikTok Account'}</div>
                                <Input disabled onChange={e=>changeInfo('tiktok', e.target.value)} value={information.tiktok}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入您的TikTok Account号，用于平台审核真实性']} />
                            </div>
                        </div>
                        {userInfo?.redAccountDetail?.mcnCode ? <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>MCN机构</div>
                                <Input disabled={true} onChange={e=>changeInfo('nickname', e.target.value)} value={userInfo?.redAccountDetail?.mcnOrganizationName}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} />
                            </div>
                        </div>:''}
                        <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonI['昵称']}</div>
                                <Input disabled={!isInformation} onChange={e=>changeInfo('nickname', e.target.value)} value={information.nickname}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonI['请输入您的昵称']} />
                                {!isInformation?<span onClick={()=>setIsInformation(!isInformation)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['真实姓名']}</div>
                                <Input disabled={!isInformation} onChange={e=>changeInfo('realName', e.target.value)} value={information.realName}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={intl.get('NewLogin')['请输入您的真实姓名']} />
                                {!isInformation?<span onClick={()=>setIsInformation(!isInformation)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{'WhatsApp'}</div>
                                <Input disabled={!isInformation} onChange={e=>changeInfo('whatsApp', e.target.value)} value={information.whatsApp}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入您的WhatsApp']} />
                                {!isInformation?<span onClick={()=>setIsInformation(!isInformation)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['邮箱号码']}</div>
                                <Input disabled={true} onChange={e=>changeInfo('email', e.target.value)} value={information.email}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入您的邮箱号']} />
                            </div>
                        </div>
                        {isPassword?<div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['验证码']}</div>
                                <Input onChange={e=>setCode(e.target.value)} value={information.verifyCode}
                                    style={{width:'210px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入邮箱验证码']} />
                                <Button disabled={!isCode} onClick={()=>getEmailCode()} style={{
                                    width: '210px', height: '48px', background: !isCode?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)',
                                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0, marginLeft:'10px',
                                }}>{isCode?textJsonNL['获取邮箱验证码']: `${time}${intl.get('account')['s后可重新发送']}`}</Button>
                            </div>
                        </div>:''}
                        <div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonI['登录密码']}</div>
                                <Input disabled={!isPassword} onChange={e=>setPassword(e.target.value)} value={password==''&&userInfo.password&&!isPassword?'************':password}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonI['请输入新的登录密码']} />
                                {!isPassword?<span onClick={()=>setIsPassword(!isPassword)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        {isInformation?<div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}></div>
                                <Button disabled={false} onClick={()=>redSetup(1)} style={{
                                    width: '210px', height: '40px', background: false?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)',
                                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                                }}>{textJsonI['确认修改']}</Button>
                            </div>
                        </div>:''}
                        {isPassword?<div style={{paddingLeft:'40px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}></div>
                                <Button disabled={codes==''||password.length<6} onClick={()=>redSetup(0)} style={{
                                    width: '210px', height: '40px', background: codes==''||password.length<6?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)',
                                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                                }}>{textJsonI['确认修改']}</Button>
                            </div>
                        </div>:''}
                    </div> : 
                    tab1Index == 1 ? 
                    // 带货信息
                    <div id='login' className='InfluencerAccountInfo' style={{width:'860px', paddingLeft:'50px', paddingTop:'50px', position:'relative'}}>
                        <div style={{}}>
                            <div id='guojia' style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, paddingRight:'16px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['所在国籍']}</div>
                                <Select
                                    size='large' showSearch disabled={!isCommerce}
                                    style={{ width: '430px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                    placeholder={<span style={{fontSize:'14px'}}>{textJsonNL['请选择您的国籍']}</span>} allowClear={true}
                                    onChange={handleChange} value={`${information.nationalityEn}`}
                                    optionLabelProp="label" getPopupContainer={() => document.getElementById('guojia')}
                                >
                                    {
                                        geolocation?.map((item,index)=>{
                                            let itemS = `${item.enName}（${item.name}）`
                                            return <Option key={index} id={item.id} icon={item.imgUrl} enName={item.enName} cn={item.name} value={itemS} label={itemS}>
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    <img src={item.imgUrl} style={{width:'14px', marginRight:'6px'}} />
                                                    {item.enName} ({item.name})
                                                </div>
                                            </Option>
                                        })
                                    }
                                </Select>
                                {!isCommerce?<span onClick={()=>setIsCommerce(!isCommerce)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{ marginTop:'16px'}}>
                            <div id='flei' style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, paddingRight:'16px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['擅长带货分类']}</div>
                                <Select
                                    mode="multiple" size='large' maxTagCount={1} disabled={!isCommerce}
                                    style={{ width: '430px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                    placeholder={<span style={{fontSize:'14px'}}>{textJsonNL['请选择擅长带货分类，不超过3个']}</span>} allowClear={true} showArrow={true}
                                    onChange={handleChanges} value={information.cargoClassificationInterpretationEn}
                                    optionLabelProp="label" getPopupContainer={() => document.getElementById('flei')}
                                >
                                    {
                                        classList?.length > 0 ? classList?.map((item,index)=>{
                                            return <Option key={index} id={item.id} name={item.desc} value={item.desc} label={item.desc}>
                                                <div style={{textAlign:'left'}}>
                                                    {item.desc}
                                                </div>
                                            </Option>
                                        }):''
                                    }
                                </Select>
                                {!isCommerce?<span onClick={()=>setIsCommerce(!isCommerce)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{marginTop:'16px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, paddingRight:'16px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['视频制作报价']}/{textJsonNL['60s内']}</div>
                                <Input disabled={!isCommerce} onChange={e=>changeInfo('videoQuotationDollar', e.target.value.replace(/[^\d.]/, ''))} value={information.videoQuotationDollar} prefix={'$'}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入您视频制作60s内的单价']} />
                                {!isCommerce?<span onClick={()=>setIsCommerce(!isCommerce)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        <div style={{marginTop:'16px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, paddingRight:'16px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['直播合作报价']}/{textJsonNL['1h内']}</div>
                                <Input disabled={!isCommerce} onChange={e=>changeInfo('liveBroadcastQuotationDollar', e.target.value.replace(/[^\d.]/, ''))} value={information.liveBroadcastQuotationDollar} prefix={'$'}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入您直播合作1h内的单价']} />
                                {!isCommerce?<span onClick={()=>setIsCommerce(!isCommerce)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                        </div>
                        {isCommerce?<div style={{paddingLeft:'65px', margin:'0 auto', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'135px', fontWeight: 600, paddingRight:'25px', textAlign:'right', color:'#888888', letterSpacing:'1px'}}></div>
                                <Button disabled={false} onClick={()=>redSetup(2)} style={{
                                    width: '210px', height: '40px', background: false?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)',
                                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                                }}>{textJsonI['确认修改']}</Button>
                            </div>
                        </div>:''}
                    </div> :
                    tab1Index == 2 ? 
                    // 收款信息
                    <div id='login' className='InfluencerAccountInfo' style={{width:'860px', paddingLeft:'80px', paddingTop:'25px', position:'relative'}}>
                        <div style={{width:'554px', height:'48px', background:'#F0F0F0', display:'flex', alignItems:'center', justifyContent:'center',
                            marginLeft:'78px'}}>
                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/wxts.png" style={{width:'81px'}} />
                            <span style={{fontSize:'12px', color:'#888888', marginLeft:'25px', letterSpacing:'1px'}}>{textJsonI['本平台结算转账提现统一使用Payoneer，请正确填写您的收款信息']}</span>       
                        </div>
                        <div style={{marginTop:'45px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['收款人真实姓名']}</div>
                                <Input disabled={!isCollection} onChange={e=>changeInfo('payoneerRealName', e.target.value)} value={information.payoneerRealName}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入收款人的真实姓名']} />
                                {!isCollection?<span onClick={()=>setIsCollection(!isCollection)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                            {isCollection?<div style={{paddingLeft:'200px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px', display:'flex', alignItems:'center'}}>
                                <img style={{width:'14px', marginRight:'6px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                                {textJsonNL['请输入注册Payoneer时的真实姓名']}
                            </div>:''}
                        </div>
                        <div style={{marginTop:'36px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonNL['收款的账号']}</div>
                                <Input disabled={!isCollection} onChange={e=>changeInfo('payoneerAccount', e.target.value)} value={information.payoneerAccount}
                                    style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonNL['请输入收款的邮箱账号']} />
                                    {!isCollection?<span onClick={()=>setIsCollection(!isCollection)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                            </div>
                            {isCollection?<div style={{paddingLeft:'200px', textAlign:'left', color:'#CCCCCC', fontSize:'12px', marginTop:'5px', display:'flex', alignItems:'center'}}>
                                <img style={{width:'14px', marginRight:'6px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                                {textJsonNL['请输入注册Payoneer时的邮箱号']}
                            </div>:''}
                        </div>
                        {isCollection?<Button onClick={()=>redSetup(3)} style={{
                            width: '210px', height: '48px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                            color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '1px', border:0,
                            marginLeft:'200px', marginTop:'35px'
                        }}>{textJsonI['确认收款信息']}</Button>:''}
                    </div> :
                    tab1Index == 3 ? 
                    // 样品收货地址
                    <SampleDeliveryAddress uid={userInfo.uid} />
                    // <div id='login' className='InfluencerAccountInfo' style={{width:'860px', paddingLeft:'50px', paddingTop:'50px', position:'relative'}}>
                    //     <div style={{marginTop:'20px'}}>
                    //         <div style={{display:'flex', alignItems:'center'}}>
                    //             <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonI['收件人姓名']}</div>
                    //             <Input disabled={!isAddress} onChange={e=>changeInfo('recipientName', e.target.value)} value={information.recipientName}
                    //                 style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={intl.get('ReleaseDemand')['请输入收件人姓名']} />
                    //             {!isAddress?<span onClick={()=>setIsAddress(!isAddress)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                    //         </div>
                    //     </div>
                    //     <div style={{marginTop:'16px'}}>
                    //         <div style={{display:'flex', alignItems:'center'}}>
                    //             <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonI['收件人手机号']}</div>
                    //             <Input disabled={!isAddress} onChange={e=>changeInfo('recipientPhone', e.target.value)} value={information.recipientPhone}
                    //                 style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={intl.get('ReleaseDemand')['请输入收件人手机号']} />
                    //             {!isAddress?<span onClick={()=>setIsAddress(!isAddress)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                    //         </div>
                    //     </div>
                    //     <div style={{marginTop:'16px'}}>
                    //         <div style={{display:'flex', alignItems:'center'}}>
                    //             <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonI['邮编']}</div>
                    //             <Input disabled={!isAddress} onChange={e=>changeInfo('postalCode', e.target.value)} value={information.postalCode}
                    //                 style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonI['请输入邮编']} />
                    //             {!isAddress?<span onClick={()=>setIsAddress(!isAddress)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                    //         </div>
                    //     </div>
                    //     <div style={{marginTop:'16px'}}>
                    //         <div style={{display:'flex', alignItems:'center'}}>
                    //             <div style={{width:'200px', fontWeight: 600, textAlign:'right', paddingRight:'20px', color:'#888888', letterSpacing:'1px'}}>{textJsonI['具体地址']}</div>
                    //             <Input disabled={!isAddress} onChange={e=>changeInfo('address', e.target.value)} value={information.address}
                    //                 style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJsonI['请输入具体的收样品地址']} />
                    //             {!isAddress?<span onClick={()=>setIsAddress(!isAddress)} style={{color:'#F7385B', width:'100px', textAlign:'center', cursor:'pointer'}}>{textJsonI['修改']}</span>:''}
                    //         </div>
                    //     </div>
                    //     {isAddress?<Button onClick={()=>redSetup(4)} style={{
                    //         width: '210px', height: '48px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    //         color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '1px', border:0,
                    //         marginLeft:'200px', marginTop:'35px'
                    //     }}>{textJsonI['确认修改']}</Button>:''}
                    // </div>
                         : '' }
                </div> :
                // 信用积分
                tabIndex == 1 ? <div style={{paddingLeft: '130px', paddingRight: lang=='zh'?'0px':'50px'}}>
                {/* tabIndex == 1 ? <div style={{paddingLeft: lang=='zh'?'130px':'30px', paddingRight: lang=='zh'?'0px':'50px'}}> */}
                    {tab2Index == 0 ? <div>
                        {credit ? <div style={{height:'90px', marginTop:'40px', display:'flex', alignItems:'center'}}>
                            <div style={{width:'140px', height:'100%', position:'relative'}}>
                                <Progress type="dashboard" strokeWidth={20} percent={credit.creditScore/950*100} gapDegree={90} strokeLinecap="butt"
                                    strokeColor={{ '0%': '#F7385B', '100%': '#FF7B79', }} showInfo={false} 
                                    // success={{ percent: 30, strokeColor: 'red' }} 
                                    trailColor={'#F6F6F6'} />
                                <div style={{width:'120px', position:'absolute', top:0, left:0, textAlign:'center', paddingTop:'33px'}}>
                                    <span style={{fontSize:'12px', color:'#888888'}}>{textJsonI['信用分']}</span>
                                    <div style={{fontSize:'24px', color:'#F7385B', lineHeight:'25px', fontWeight:'bold'}}>{credit.creditScore}</div>
                                </div>
                                <div style={{width:'1px', height:'22px', position:'absolute', top:'30px', left:'15px', background:'#843030',
                                transform:'rotate(-60deg)', opacity:'0.2'}}></div>
                                <div style={{width:'1px', height:'22px', position:'absolute', top:'4px', left:'43px', background:'#843030',
                                transform:'rotate(-30deg)', opacity:'0.2'}}></div>
                            </div>
                            <div>
                                <Tooltip title={<div style={{color:'#F7385B'}}>{textJsonI['当前信用等级']}：{creditLevelList[credit.creditLevel]}</div>} color={'#FFE7E9'} placement={'right'} visible={true}
                                    >
                                    <div style={{width:'1px', height:'34px',position:'relative', left:'-15px'}}></div>
                                </Tooltip>
                                <div style={{fontSize:'12px', color:'#888888', display:'flex', alignItems:'center', marginTop:'10px', marginLeft:'16px'}}>
                                    {textJsonI['本月成长值']} {credit.growthValue}
                                    <span onClick={()=>setTixian(true)} style={{color:'#F7385B', borderBottom:'1px solid #F7385B', cursor:'pointer', marginLeft:'4px'}}>{textJsonI['查看明细']}</span>，
                                    {lang=='zh'?<span>还需{credit.upgradeRequiredGrowthValue}下月可升级为优秀等级</span>:<span>Still need {credit.upgradeRequiredGrowthValue} to be upgraded to excellent level next month</span>}
                                    <div style={{width:'200px', position:'relative'}}>
                                        <Progress strokeColor={{ '0%': '#FF7B79', '100%': '#F7385B', }}
                                            percent={(credit.upgradeNextGrowthValue-credit.upgradeRequiredGrowthValue)/credit.upgradeNextGrowthValue*100} status="active" showInfo={false} style={{width:'200px', position:'relative', left:'10px', top:'-2px'}}
                                        />
                                        <div style={{width:'50px', textAlign:'center', position:'absolute', left: ((credit.upgradeNextGrowthValue-credit.upgradeRequiredGrowthValue)/credit.upgradeNextGrowthValue*100 -9)+'%', top:'-10px'}}>{credit.upgradeNextGrowthValue-credit.upgradeRequiredGrowthValue}</div>
                                        <span style={{position:'absolute', right: '-20px', top:'-10px'}}>{credit.upgradeNextGrowthValue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>:''}
                        <div style={{width:'700px', marginTop:'50px'}}>
                            <div style={{width:'100%', borderBottom:'1px solid #F6F6F6', paddingBottom:'20px', fontWeight:'bold'}}>{textJsonI['信用等级评估记录']}</div>
                            <div id='steps' style={{display:'flex', marginTop:'20px'}}>
                                <div style={{paddingTop:'5px', marginRight:'48px', lineHeight:'25px'}}>
                                    {
                                        creditLog?.map((item,index)=>{
                                            return <div key={index} style={{height:'62.85px', color:'#333', fontWeight:'bold'}}>{item.evaluationTime}</div>
                                        })
                                    }
                                </div>
                                <div>
                                    <Steps current={0} progressDot={customDot} direction="vertical">
                                        {
                                            creditLog?.map((item,index)=>{
                                                return <Step key={index} title={item.creditScore} description={lang === 'zh' ? item.evaluationResults : item.evaluationResultsEn} />
                                            })
                                        }
                                    </Steps>
                                </div>
                                <div style={{paddingTop:'5px', marginLeft:'80px', lineHeight:'25px'}}>
                                    {
                                        creditLog?.map((item,index)=>{
                                            return <div key={index} style={{height:'62.85px', color:'#333', fontWeight:'bold'}}>{textJsonI['信用等级']}：{creditLevelList[item.creditLevel]}</div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div> :
                    tab2Index == 1? <div className='denji' style={{width:'1150px', position:'relative', left:'-20px'}}>
                        <div style={{paddingTop:'40px', paddingBottom:'34px', borderBottom:'1px solid #F6F6F6'}}>
                            <div style={{fontSize:'20px', fontWeight:'bold'}}>{textJsonI['BrandGoGo红人信用等级升级规则']}</div>
                            <div style={{color:'#888888', fontSize:'12px'}}>{textJsonI['为了给商家提供更好的服务，为了给红人创造更多的合作机会，BrandGoGo推出的信用积分成长体系，快来看看等级体系规则吧！']}</div>
                        </div>
                        <div style={{marginTop:'30px', display:'flex', justifyContent:'space-between', paddingBottom:'140px'}}>
                            <div style={{width:'439px', minWidth:'439px'}}>
                                <div style={{display:'flex', alignItems:'center', fontWeight:'bold'}}>
                                    <div style={{width:'4px', height:'16px', background:'linear-gradient(180deg, #F7385B, #FF716E)', 
                                        borderRadius:'2px', marginRight:'7px'}}></div>{textJsonI['信用等级划分及对应等级门槛']}
                                </div>
                                <div style={{fontSize:'12px', color:'#888888', marginTop:'12px'}}>{textJsonI['信用等级将根据你的成长值每月进行一次系统评定。']}</div>
                                <div style={{width:'100%', height:'250px'}}>
                                    <Column {...config} />
                                </div>
                                <div style={{width:'100%', background:'rgb(246, 246,246)', textAlign:'center', fontWeight:500, borderRadius:'10px', marginTop:'40px'}}>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center', background:'rgb(240,240,240)',
                                    borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}> 
                                        <div style={{width:'179px'}}>{textJsonI['信用等级']}</div> <div style={{width:'calc(100% - 179px)'}}>{textJsonI['对应等级门槛']}</div>
                                    </div>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center', borderBottom:'1px solid #EEEEEE'}}> 
                                        <div style={{width:'179px', height:'100%', lineHeight:'40px', borderRight:'1px solid #EEEEEE'}}>{textJsonI['较差']}</div> <div style={{width:'calc(100% - 179px)'}}>--</div>
                                    </div>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center', borderBottom:'1px solid #EEEEEE'}}> 
                                        <div style={{width:'179px', height:'100%', lineHeight:'40px', borderRight:'1px solid #EEEEEE'}}>{textJsonI['中等']}</div> 
                                        <div style={{width:'calc(100% - 179px)'}}>{textJsonI['成长值']} 
                                            <span style={{margin:'0 15px', fontWeight:'bold', fontSize:'15px'}}>≥</span>200000
                                        </div>
                                    </div>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center', borderBottom:'1px solid #EEEEEE'}}> 
                                        <div style={{width:'179px', height:'100%', lineHeight:'40px', borderRight:'1px solid #EEEEEE'}}>{textJsonI['良好']}</div> 
                                        <div style={{width:'calc(100% - 179px)'}}>{textJsonI['成长值']} 
                                            <span style={{margin:'0 15px', fontWeight:'bold', fontSize:'15px'}}>≥</span>250000
                                        </div>
                                    </div>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center', borderBottom:'1px solid #EEEEEE'}}> 
                                        <div style={{width:'179px', height:'100%', lineHeight:'40px', borderRight:'1px solid #EEEEEE'}}>{textJsonI['优秀']}</div> 
                                        <div style={{width:'calc(100% - 179px)'}}>{textJsonI['成长值']} 
                                            <span style={{margin:'0 15px', fontWeight:'bold', fontSize:'15px'}}>≥</span>300000
                                        </div>
                                    </div>
                                    <div style={{fontSize:'13px', display:'flex', height:'40px', alignItems:'center',}}> 
                                        <div style={{width:'179px', height:'100%', lineHeight:'40px', borderRight:'1px solid #EEEEEE'}}>{textJsonI['极好']}</div> 
                                        <div style={{width:'calc(100% - 179px)'}}>{textJsonI['成长值']} 
                                            <span style={{margin:'0 15px', fontWeight:'bold', fontSize:'15px'}}>≥</span>350000
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='menkan' style={{marginLeft:'140px'}}>
                                <div style={{display:'flex', alignItems:'center', fontWeight:'bold'}}>
                                    <div style={{width:'4px', height:'16px', background:'linear-gradient(180deg, #F7385B, #FF716E)', 
                                        borderRadius:'2px', marginRight:'7px'}}></div>{textJsonI['信用等级划分及对应等级门槛']}
                                </div>
                                <div style={{color:'#888888', fontSize:'12px', marginTop:'15px', lineHeight:'20px'}}>
                                    <div>1、{textJsonI['在BrandGoGo平台上（PC端网页、移动端App）进行短视频或直播需求合作，获取对应的成长值']}；</div> 
                                    <div>2、{textJsonI['红人完成收款信息的填写，即可获得10000成长值']}；</div>
                                    {
                                        lang=='zh'?<div>3、<span style={{color:'#F7385B'}}>1分=1000成长值，</span>在平台上每赚取<span style={{color:'#F7385B'}}>1元 = 1成长值</span>，赚取金额以实际入账钱包的金额为准；</div>:
                                        <div>3、{textJsonI['1分=1000成长值，在平台上每赚取1元 = 1成长值，赚取金额以实际入账钱包的金额为准']}；</div>
                                    }
                                    {
                                        lang == 'zh' ?<div>4、若红人在合作中，未在截止时间内上传短视频或未在指定时间进行直播将<span style={{color:'#F7385B'}}>扣除10000成长值</span>，未按商家要求将视频挂在TikTok主页上或提前下架视频将<span style={{color:'#F7385B'}}>扣除20000成长值</span>，识别红人账户安全风险及其他违规行为，视情节严重性进行扣除相应成长值；</div>:
                                        <div>4、{textJsonI['若红人在合作中，未在截止时间内上传短视频或未在指定时间进行直播将扣除10000成长值，未按商家要求将视频挂在TikTok主页上或提前下架视频将扣除20000成长值，识别红人账户安全风险及其他违规行为，视情节严重性进行扣除相应成长值']}；</div>
                                    }
                                    <div>5、{textJsonI['3次以上以上行为，平台将会拉入黑名单。']}</div>
                                </div>
                                <div style={{display:'flex', alignItems:'center', fontWeight:'bold', marginTop:'70px'}}>
                                    <div style={{width:'4px', height:'16px', background:'linear-gradient(180deg, #F7385B, #FF716E)', 
                                        borderRadius:'2px', marginRight:'7px'}}></div>{textJsonI['信用等级进退说明']}
                                </div>
                                <div style={{color:'#888888', fontSize:'12px', marginTop:'15px', lineHeight:'20px'}}>
                                    {
                                        lang == 'zh' ?<div>1、红人成功入驻后，平台将赋予<span style={{color:'#F7385B'}}>良好600分</span>的初始信用等级； </div>:
                                        <div>1、{textJsonI['红人成功入驻后，平台将赋予良好600分的初始信用等级']}； </div>
                                    } 
                                    {
                                        lang == 'zh' ?<div>2、<span style={{color:'#F7385B'}}>每个自然月的第一天</span>为信用等级结算日。若你的成长值已达到更高等级的升级标准，你的等级将会实时升级；若您未达到更高等级的升级标准，将在结算日，根据你上个周期的成长值和等级状态，进行对应的信用等级评定；</div>:
                                        <div>2、{textJsonI['每个自然月的第一天为信用等级结算日。若你的成长值已达到更高等级的升级标准，你的等级将会实时升级；若您未达到更高等级的升级标准，将在结算日，根据你上个周期的成长值和等级状态，进行对应的信用等级评定；']}</div>
                                    }
                                    <div>3、{textJsonI['当你的信用等级达到最高分时，将不再继续累加维持分数平稳']}；</div>
                                </div>
                            </div>
                        </div>
                    </div> :''}
                </div> : 
                tabIndex == 2 ? 
                // 消息通知
                <div style={{width:'100%', paddingLeft:'100px', paddingRight:'100px'}}>
                    {tab3Index == 0? <div>
                        {
                            news1List.informationList?.length > 0 ?
                            news1List.informationList?.map((item,index)=>{
                                return <div key={index} style={{display:'flex', marginTop:'40px'}}>
                                    <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                        <img style={{width:'30px', height:'30px'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/xitong.png" />
                                    </Badge>
                                    <div style={{width:'100%', marginLeft:'20px'}}>
                                        <div style={{display:'flex', cursor:'pointer'}}>
                                            <div onClick={()=>item.extraInfo ? changeChecked(false, index, 1):null} style={{maxWidth:'calc(100% - 80px)', fontWeight:'bold', color: item.readStatus == 1?'#888888':'#333'}}>{lang === 'zh' ? item.title : item.titleEn || item.title}</div>
                                            {
                                                item.extraInfo ? <div style={{width:'80px', minWidth:'80px', height:'20px', fontSize:'12px', color:'#888888',
                                                background:'#F0F0F0', borderRadius:'4px', textAlign:'center', marginLeft:'12px', fontWeight:'normal'}}
                                                onClick={()=>changeChecked(true, index, 1)}>{textJsonI['查看详情']} <RightOutlined /></div>
                                                : !item.isChecked ? <div style={{width:'80px', minWidth:'80px', height:'20px', fontSize:'12px', color:'#888888',
                                                background:'#F0F0F0', borderRadius:'4px', textAlign:'center', marginLeft:'12px', fontWeight:'normal'}}
                                                onClick={()=>changeChecked(true, index, 1)}>{textJsonI['展开内容']} <DownOutlined /></div>
                                                : <div style={{width:'80px', minWidth:'80px', height:'20px', fontSize:'12px', color:'#888888',
                                                background:'#F0F0F0', borderRadius:'4px', textAlign:'center', marginLeft:'12px', fontWeight:'normal'}}
                                                onClick={()=>changeChecked(false, index, 1)}>{textJsonI['收起内容']} <UpOutlined /></div>
                                            }
                                        </div>
                                        <div style={{fontSize:'12px', color:'#888888'}}>{item.createTime}</div>
                                        {item.isChecked ? <div style={{width:'100%', maxWidth:'100%', background:'#F0F0F0', borderRadius:'4px', marginTop:'14px',
                                        color:'#888888', fontSize:'12px', padding:'10px 25px', lineHeight:'22px'}}>
                                            <div style={{width:'100%'}} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                        </div>:''}
                                    </div>
                                </div>
                            })
                            :
                            <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                <div style={{ marginTop: '23px' }}>{intl.get('EnterpriseAccount')['暂无消息通知~']}</div>
                            </div>
                        }
                        
                    </div> :
                    tab3Index == 1 ? 
                    <div>
                        {
                            news2List.informationList?.length > 0 ?
                            news2List.informationList?.map((item,index)=>{
                                return <div key={index} style={{display:'flex', marginTop:'40px'}}
                                onClick={()=>changeChecked(true, index, 2)}>
                                    <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                        <img style={{width:'30px', height:'30px'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/shangjia.png" />
                                    </Badge>
                                    <div style={{width:'100%', marginLeft:'20px'}}>
                                        <div style={{display:'flex', cursor:'pointer'}}>
                                            <span style={{fontWeight:'bold', color: item.readStatus == 1?'#888888':'#333'}}>{lang === 'zh' ? item.title : item.titleEn || item.title}</span>
                                            <div style={{width:'80px', minWidth:'80px', height:'20px', fontSize:'12px', color:'#888888',
                                            background:'#F0F0F0', borderRadius:'4px', textAlign:'center', marginLeft:'12px', fontWeight:'normal'}}>{textJsonI['查看详情']} <RightOutlined /></div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#888888'}}>{item.createTime}</div>
                                    </div>
                                </div>
                            })
                            :
                           <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                               <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                               <div style={{ marginTop: '23px' }}>{intl.get('EnterpriseAccount')['暂无消息通知~']}</div>
                           </div>
                        }
                    </div> : 
                    tab3Index == 2 ? 
                    <div>
                        {
                            news3List.informationList?.length > 0 ?
                            news3List.informationList?.map((item,index)=>{
                                return <div key={index} style={{display:'flex', marginTop:'40px'}}
                                onClick={()=>changeChecked(true, index, 3)}>
                                    <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                        <img style={{width:'30px', height:'30px', borderRadius:'15px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/'+(item.title.split('已接受').length > 1 ? 'jieshou' : 'weijieshou')+'.png'} />
                                    </Badge>
                                    <div style={{width:'100%', marginLeft:'20px'}}>
                                        <div style={{display:'flex', cursor:'pointer'}}>
                                            <span style={{fontWeight:'bold', color:item.readStatus == 1?'#888888':'#333'}}>{lang === 'zh' ? item.title : item.titleEn || item.title}</span>
                                            <div style={{width:'80px', minWidth:'80px', height:'20px', fontSize:'12px', color:'#888888',
                                            background:'#F0F0F0', borderRadius:'4px', textAlign:'center', marginLeft:'12px', fontWeight:'normal'}}>{textJsonI['查看详情']} <RightOutlined /></div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#888888'}}>{item.createTime}</div>
                                    </div>
                                </div>
                            })
                            :
                            <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                <div style={{ marginTop: '23px' }}>{intl.get('EnterpriseAccount')['暂无消息通知~']}</div>
                            </div>
                        }
                    </div> : '' }
                </div> :''}
                {tabIndex == 0 && tab1Index == 0 ?<div style={{textAlign:'center', marginTop:'50px'}}>
                    <div>
                        <Image src={userInfo?.avatar || userInfo?.redAccountDetail?.avatarMedium || ''} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'}
                        style={{ width: '100px', height: '100px', borderRadius: '50px' }} /></div>
                        <Upload {...uploadProps}>
                            <div style={{ color: '#F7385B', cursor: 'pointer', border:'1px solid #F7385B',
                            marginTop:'10px', padding:'3px 10px', borderRadius:'4px' }}>
                                {textJson["上传头像"]}
                            </div>
                        </Upload>
                </div>:''}
                
            </div> 
            










            <Modal centered visible={isTixian} footer={null} closable={false}
                getContainer={() => document.getElementById('InfluencerAccount')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 1000, borderRadius: '10px', zIndex:1500
                }}
            >
                <div style={{ padding: '55px 30px 30px 30px', borderRadius: '10px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>{textJson['提现记录']}</h3>
                    <div style={{
                        width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                        background: '#F6F6F6', marginTop: '40px', fontWeight: 500
                    }}>
                        <div style={{ width: '64%', paddingLeft: '45px', paddingRight: '20px' }}>{textJsonI['内容']}</div>
                        <div style={{ width: '20%' }}>{textJsonI['完成时间']}</div>
                        <div style={{ width: '15%' }}>{textJsonI['成长值']}</div>
                    </div>
                    <div id='scrollableDiv' style={{ width: '100%', height: '60vh', overflowY: 'auto' }}>
                        <Spin tip={`${textJsonI['数据加载中']}...`} spinning={loading}>
                            <InfiniteScroll
                                dataLength={dataTi.length} scrollThreshold={'20px'}
                                next={loadMoreData}
                                hasMore={dataTi.length < totalTi}
                                // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                                endMessage={dataTi.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={dataTi} grid={{ gutter: 0, column: 1 }}
                                    renderItem={(item, index) => <div key={index} style={{
                                        width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                                        background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                                    }}>
                                        <div className='over' style={{ width: '65%', paddingLeft: '45px', fontWeight: 'bold', fontSize: '16px',
                                        paddingRight:'20px' }}>
                                            {item.content}
                                        </div>
                                        <div style={{ width: '20%' }}>{item.finishTime}</div>
                                        <div style={{ width: '15%', color: item.growthValue>=0?'#68C33B':'#F7385B'}}>{item.growthValue}</div>
                                    </div>}
                                />
                            </InfiniteScroll>
                        </Spin>
                    </div>

                    <CloseCircleOutlined style={{
                        position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                        cursor: 'pointer'
                    }}
                        onClick={() => closeTixian()} />
                </div>
            </Modal>
        </div>
    ) :''
}

export default withRouter(InfluencerAccount);