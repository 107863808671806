import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../utils/withRouter'
import { CloseCircleOutlined, UpSquareTwoTone } from '@ant-design/icons';
import { Modal, Input, Button, notification, Select, Checkbox } from 'antd';
import intl from 'react-intl-universal';
// import geolocation from '../../utils/geolocation'
import * as Api from '../../service/https'
import store from '../../utils/redux/index'

const { Option } = Select;

const BloggerSigning = (props) => {
    const [textJson, setTextJson] = useState(intl.get('NewLogin'))
    const intervalRef = useRef(null);
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    useEffect(() => {
        // console.log(props)
        
        getCargo()
        getCountry()
        return () => {
            if(intervalRef) clearInterval(intervalRef?.current)
        }
    },[])

    const [classList, setClassList] = useState(0) 
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                setClassList(res.data)
            }
        })
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }

    const tabList = [textJson['基础信息'], textJson['带货信息'], textJson['合同签署']]
    const [tabIndex, setTabIndex] = useState(0)
    const changeIndex = bools=>{
        let info = JSON.parse(JSON.stringify(information))
        let i = JSON.parse(JSON.stringify(tabIndex))
        i = i + (bools ? 1 : -1)
        if(bools && tabIndex == 2){
            let obj = info
            obj.nationality = {id: obj.nationality}
            obj.cargoClassification.map((item, index)=>{
                obj.cargoClassification[index] = {id: item} 
            })
            console.log(obj)
            Api.getMcn_addredAccount(obj).then(res=>{
                if(res.code == 200){
                    setTabIndex(i)
                }
            })
        }else{
            setTabIndex(i)
        }
    }
    const [information, setInformation] = useState({
        tiktok:'', email:'', nationality: undefined, 
        realName:'', whatsApp:'', cargoClassification: [], 
        videoQuotation:'', liveBroadcastQuotation:'', 
    })
    const changeInfo = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }

    const handleChange =(value, e)=> {
        // console.log(`selected ${value}`);
        changeInfo('nationality', value)
    }
    const handleChangeFlei =(value, e)=> {
        if(e.length < 4){
            let list = []
            e.map((item)=>{
                list.push(item.id)
            })
            console.log(list)
            changeInfo('cargoClassification', list)
        }
    }

    const [isCheckedR, changeR] = useState(false)



    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };

    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    // 获取验证码
    const getEmailCode = ()=>{
        if(isCode && information.email!=''){
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getEmailCode({toEmail: information.email}).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(()=>{
                        if(times == 0){
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times --)
                    },1000)
                    intervalRef.current = downTime
                }
            })
        }
    }


    const isNext = ()=>{
        if(tabIndex == 0) return information.tiktok!='' && information.realName!='' && information.whatsApp!='' && information.email!=''
        if(tabIndex == 1) return information.nationality!=undefined && information.nationality!='' && information.cargoClassification.length>0
        && information.videoQuotation!='' && information.liveBroadcastQuotation!=''
        // if(tabIndex == 1) return true
        if(tabIndex == 2) return isCheckedR
    }



    return <div>
        <Modal centered visible={true} footer={null} closable={false}
            getContainer={() => document.getElementById('login')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                padding: '70px 90px', width: 1000
            }}
        >
            <CloseCircleOutlined onClick={()=>props.close()}
            style={{color:'#888888', fontWeight:700, fontSize:'20px', cursor:'pointer', position:'absolute', top:'30px', right:'30px'}} />
            <div style={{ fontSize: '20px', fontWeight: 'bold', lineHeight:'20px', textAlign: 'center' }}>{intl.get('AccountRecharge')['添加机构红人']}</div>
            <div style={{width:'70%', marginLeft:'15%',display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'50px', paddingLeft:'40px'}}>
                {
                    tabList.map((item,index)=>{
                        return <div key={index} style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'20px', minWidth:'20px', height:'20px', borderRadius:'50%', background: index<=tabIndex?'#F7385B':'#CCC', 
                                color:'#FFF', lineHeight:'20px', fontWeight:'bold', textAlign:'center'}}>{index+1}</div>
                            <span style={{color: index==tabIndex?'#333':index<tabIndex?'#F7385B':'#CCC' ,marginLeft:'5px', marginRight:'45px', fontWeight: 'bold'}}>{item}</span>
                            {index<2?<img style={{width:'59px', marginRight:'8px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/'+(index<tabIndex?'youcai':'youhui')+'.png'} />:''}
                        </div>
                    })
                }
            </div>
            {
                tabIndex == 0 ? <div style={{ width: '100%', height: '350px', marginTop: '30px', textAlign: 'right', paddingRight:'70px' }}>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'125px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{'TikTok Account'}</div>
                            <Input onChange={e=>changeInfo('tiktok', e.target.value)} value={information.tiktok}
                                style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您的TikTok Account号，用于平台审核真实性']} />
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'125px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['真实姓名']}</div>
                            <Input onChange={e=>changeInfo('realName', e.target.value)} value={information.realName}
                                style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您的真实姓名']} />
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'125px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{'WhatsApp'}</div>
                            <Input onChange={e=>changeInfo('whatsApp', e.target.value)} value={information.whatsApp}
                                style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您的WhatsApp']} />
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'125px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['邮箱号码']}</div>
                            <Input onChange={e=>changeInfo('email', e.target.value)} value={information.email}
                                style={{width:'430px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您的邮箱号']} />
                        </div>
                    </div>
                </div> :
                tabIndex == 1 ? <div style={{ width: '100%', height: '350px', marginTop: '30px', textAlign:'right', paddingRight:'90px' }}>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px'}}>
                        <div id='guojia' style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'200px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['所在国籍']}</div>
                            <Select
                                size='large' showSearch
                                style={{ width: '430px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择您的国籍']}</span>} allowClear={true}
                                onChange={handleChange} value={information.nationality}
                                optionLabelProp="label" getPopupContainer={() => document.getElementById('guojia')}
                            >
                                {
                                    geolocation?.map((item,index)=>{
                                        let itemS = `${item.enName}（${item.name}）`
                                        return <Option key={index} id={item.id} icon={item.imgUrl} enName={item.enName} cn={item.name} value={item.id} label={itemS}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <img src={item.imgUrl} style={{width:'14px', marginRight:'6px'}} />
                                                {itemS}
                                            </div>
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'570px', marginTop:'20px'}}>
                        <div id='flei' style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'200px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['擅长带货分类']}</div>
                            <Select
                                mode="multiple" size='large' maxTagCount={1}
                                style={{ width: '430px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                placeholder={<span style={{fontSize:'14px'}}>{intl.get('AccountRecharge')['请选择机构擅长类型，最多选3个']}</span>} allowClear={true} showArrow={true}
                                onChange={handleChangeFlei} value={information.cargoClassification}
                                optionLabelProp="label" getPopupContainer={() => document.getElementById('flei')}
                            >
                                {
                                    classList?.length > 0 ? classList.map((item,index)=>{
                                        return <Option key={index} id={item.id} value={item.id} label={item.desc}>
                                            <div style={{textAlign:'left'}}>
                                                {item.desc}（{item.desc}）
                                            </div>
                                        </Option>
                                    }):''
                                }
                            </Select>
                        </div>
                        <div style={{paddingLeft:'170px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px', display:'flex', alignItems:'center'}}>
                            <img style={{width:'14px', marginRight:'6px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                            {textJson['擅长带货分类最多可选3个']}
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'615px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'193px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['视频制作报价']}</div>
                            <Input onChange={e=>changeInfo('videoQuotation', e.target.value.replace(/[^\d.]/, ''))} value={information.videoQuotation} prefix={'¥'}
                                style={{width:'300px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您视频制作60s内的单价']} />
                            <span style={{color:'#CCCCCC', marginLeft:'11px'}}>{textJson['60s内']}</span>
                        </div>
                        <div style={{paddingLeft:'190px', textAlign:'left', color:'#CCCCCC', fontSize:'12px', marginTop:'5px', display:'flex', alignItems:'center'}}>
                            <img style={{width:'14px', marginRight:'6px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                            {textJson['该报价用于商家的条件筛选，后期您可在个人中心修改此报价']}
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', margin:'0 auto', width:'615px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'193px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['直播合作报价']}</div>
                            <Input onChange={e=>changeInfo('liveBroadcastQuotation', e.target.value.replace(/[^\d.]/, ''))} value={information.liveBroadcastQuotation} prefix={'¥'}
                                style={{width:'300px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入您直播合作1h内的单价']} />
                            <span style={{color:'#CCCCCC', marginLeft:'11px'}}>{textJson['1h内']}</span>
                        </div>
                        <div style={{paddingLeft:'190px', textAlign:'left', color:'#CCCCCC', fontSize:'12px', marginTop:'5px', display:'flex', alignItems:'center'}}>
                            <img style={{width:'14px', marginRight:'6px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                            {textJson['该报价用于商家的条件筛选，后期您可在个人中心修改此报价']}
                        </div>
                    </div>
                </div> : tabIndex == 2 ? <div style={{ width: '100%', height: '350px', marginTop: '30px', textAlign: 'left'}}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', lineHeight:'20px', textAlign: 'center', marginBottom:'20px' }}>{textJson['博主入驻合同细节']}</div>
                    <div id='hetong' style={{width:'600px', height:'250px', marginLeft:'calc((100% - 600px) / 2)', overflowY:'auto'}}>
                        <h3 style={{textAlign:'center', letterSpacing:'0px'}}>Dear users, welcome to use the "Brandgogo" platform and related services.</h3>
                        <div>
                        To provide you with better services, before you access and use the "Brandgogo" platform and related services, please be sure to read and fully understand the terms of this agreement, especially those concerning the exemption or limitation of liability, license rights, and information use. Terms, Applicability of Law and Dispute Resolution Terms, etc. Among them, important content such as the terms of exemption or limitation of liability will be reminded in bold form, and you should read it carefully.

Unless you fully accept the entire contents of this agreement, you have no right to register, log in to the "Brandgogo" platform, use the "Brandgogo" platform in any way, or obtain any services provided by the "Brandgogo" platform (hereinafter collectively referred to as "use"). Once you use the "Brandgogo" platform and related services, you are deemed to have fully understood this agreement and agree to be bound by this agreement and other agreements and rules related to the "Brandgogo" platform and related services as a party to this agreement. At that time, you have no right to claim that this agreement is invalid and/or request to revoke this agreement because you have not read the content of this agreement or have not obtained the response, answer, and handling of your inquiry from the service provider.

<br/>
<h3>1. Scope of application and definition</h3> 
1. This agreement is an agreement between you and Suzhou Zhenchuang Technology Co., Ltd. (hereinafter referred to as "the company") and you to register, log in, use the "Brandgogo" platform, and obtain the relevant services provided by the "Brandgogo" platform. The company has the right to unilaterally decide, according to the needs of the "Brandgogo" platform and related services or operations, to arrange or designate its affiliates, controlling companies, successor companies, or third-party companies recognized by the company to continue to operate the "Brandgogo" platform. In addition, some of the services involved in this agreement may be provided to you by the company's affiliates and controlling companies. If you know and agree to accept the above services, you are deemed to accept the relevant rights and obligations between the two parties and are also subject to this agreement. constraint.
<br/>
<br/>
2. "Brandgogo" platform refers to the network platform operated by the company or its affiliates with the domain name www.brandgogo.com, which focuses on providing users with video shooting, material production, webcasting, or other content service tasks (hereinafter referred to as "content"). Service Tasks" or "Tasks") transaction-related management and technical services. Referred to in this Agreement as the "Platform".
<br/>
<br/>
3. "Brandgogo" service refers to the MCN (Multi-Channel Network) institutions, talents, and creators who cooperate with "Brandgogo" celebrities, service providers, or "Brandgogo" through technical means provided by the company for users. Content services, task transactions, and paid services for management between creators and MCN masters. The specific requirements, procedures, and methods of different types of task transactions will be stipulated in the relevant terms of this agreement and platform rules, and users should abide by them.
<br/>
<br/>
4. "Users" refer to natural persons, legal persons, and other organizations who use the "Brandgogo" platform and related services after registering, completing the platform certification, and verification under the relevant rules and agreements of the "Brandgogo" platform. Referred to in this Agreement as "User" or "You".
<br/>
<br/>
5. "Customer" refers to the actual demander who publishes tasks through the "Brandgogo" platform and promotes their products or services through the services completed by the master/creator/MCN master, including the client and the agency company entrusted by the client.
<br/>
<br/>
6. "Brandgogo" service provider" or "service provider" refers to a natural person, legal person, or other organization with rich experience in script creation, video planning, creativity, filming, production, and webcasting services.
<br/>
<br/>
7. "Brandgogo" certified MCN (supplier)" or "certified MCN (supplier)" means that it has passed the platform certification, meets the platform entry and order standards, can provide operation, business, and other services for talents, and has a large number of high-quality products. The natural person, legal person, or other organization of We Media Talent Resources (referred to as "MCN Supplier" or "MCN Organization").
<br/>
<br/>
8. "Masters" or "Creators" refer to those who have a certain influence and self-media resources, have the right to accept tasks posted by users, and provide corresponding services by the content, requirements, standards, etc. listed in the specific tasks, and the services have passed the user's acceptance. A natural person, legal person, or other organization; "MCN talent" refers to a talent who provides services such as content creation, editing, production, publishing, webcasting, and other services to customers through the "Brandgogo" platform as a certified MCN supplier of the "Brandgogo" platform.
<br/>
<br/>
9. "Service Fees" means the related fees payable by the Client for the Posted Requirements or Tasks. When a customer publishes a demand on the Brandgogo platform, the customer needs to report a reasonable commission range for the celebrity or celebrity to choose from in the scene. All services or needs provided by this platform to celebrities or MCN institutions are paid services provided by customers and provide a pool of merchant needs for matching or selection, and the needs are free to choose to charge rules: charging by time/charging by commission ratio / independent range quotation /Pit fee + commission and other methods, all celebrities or institutions support scanning code to settle in or others, and other institutional influencers can scan code to settle in and automatically belong to the institution.
<br/>
<br/>
10. Cooperation period: It will be valid for a long time from the date you click to agree to this agreement (but if other terms of this agreement are otherwise agreed, the validity period of other terms shall be from the agreement), "Brandgogo" platform has the right to cancel or terminate this agreement in advance without needing to. If you are responsible for any breach of contract, if you terminate or rescind this agreement in advance, you should settle the creditor's rights and debts; if you are a master, creator, MCN master, or MCN institution, no matter what causes this agreement to terminate, you shall perform After completing the accepted tasks, the platform will complete the settlement with you according to your actual performance. If you refuse or delay the fulfillment of the received order and cause losses to the platform or a third party, you should be fully liable for compensation, and the platform has the right to never The corresponding losses will be deducted from the settlement fee and the deposit of the MCN institution; if the MCN institution has a serious breach of contract, the platform has the right to deduct all the deposit of the MCN institution. If you are an "MCN expert", please ensure that the cooperation contract signed between you and the "Brandgogo" platform certified MCN supplier is within the validity period during the use of this service, otherwise the company has the right to terminate or cancel this agreement immediately.
<br/>
<br/>
11. The content of this agreement also includes the agreements, rules, and other content on the platform and related services and tasks that the company has released and may continue to release and update in the future. The foregoing contents shall take effect from the date of announcement or notice or the date specified in the announcement and notice, and become an integral part of this agreement. If you continue to use the "Brandgogo" platform after the foregoing contents take effect, it shall be deemed that you accept and voluntarily abide by the foregoing.


<br/>
<h3>
2.About the account</h3>
1. The platform provides you with a registration channel. You have the right to choose a legal character combination for your account and set a password that meets security requirements. The account and password you set are the credentials you use to log in to the platform and use the platform and related services as you.
<br/>
<br/>
2. You understand and promise that the account you set up shall not violate national laws and regulations and the relevant rules of the company, that your account name and other registration information and other information shall not contain illegal information and bad information, and you shall not use the name of others without the permission of others. (including but not limited to fraudulently using other people's names, names, font sizes, etc., to create an account), and malicious registration of platform accounts (including but not limited to frequent registration, batch registration of accounts, etc.) is prohibited. You must abide by relevant laws and regulations in the process of account registration and use, and shall not carry out any acts that infringe on national interests, damage the legitimate rights and interests of third parties, or harm social morality.
<br/>
<br/>
3. The ownership and related rights and interests of the account you registered on the platform belong to the company, and you only have the right to use the account after completing the registration process. Your registered account on the platform is only for your use. Without the written consent of the company, it is prohibited to give, borrow, rent, transfer, sell, or otherwise permit a third party to use your account in any form. If the company finds out or has reasonable reasons to believe that the user is not the account owner or has the right to use it, to protect the security of the account, the company has the right to suspend or terminate the provision of services to the account and cancel the account without notifying you. There is no need to bear legal responsibility to the user who registered the account.
<br/>
<br/>
4. The platform hereby reminds you: To ensure the security of your account, you are responsible for properly keeping and maintaining the security and confidentiality of your account and password. Do not disclose account numbers and passwords to others under any circumstances. You should notify the company immediately if you discover that others use your account without permission or if any other security breach occurs. You must take full legal responsibility for all activities in the name of the registered account, including but not limited to any information and task release, modification, quotation, undertaking task orders, recharge, payment, and other operations that may be caused by you on the platform. all legal responsibilities.
<br/>
<br/>
5. After losing or forgetting the password of your account, you can follow the company's appeal channel to promptly appeal and request to retrieve the account or password. You should provide password-protected information to increase the security of your account, and can apply to the company to retrieve your account by filling out a complaint form with the initial registration information and password-protected information. You understand and agree that the company's password retrieval mechanism only needs to identify that the information filled in the appeal form is consistent with the information recorded in the system, but cannot identify whether the complainant is the real authorized user of the account. The company especially reminds you that you should keep your account and password properly. When you are finished using it, you should exit safely. You may suffer account theft or password loss due to your improper storage, and you are responsible for it.
<br/>
<br/>
6. Third-party account authorization login: In addition to registering a platform account by yourself, you can also choose to authorize the use of user accounts under other platforms that you legally own or that have been legally authorized by others, including but not limited to the company and/or its affiliates. Log in, register, and use the "Brandgogo" platform and related services. When you log in with the above-mentioned existing account, you should ensure that the corresponding account has been registered with the real name, and you should abide by the platform's user agreement and other terms of the agreement, and the relevant terms in this agreement shall also apply. If you log in with another person's account that has been legally authorized by others, you should ensure that you have obtained all legal authorizations. If there is a dispute arising from the use of the other person's account to log in to the platform or related matters, you should be responsible for resolving and ensuring that the platform is exempt from any liability for compensation.
<br/>
<br/>
7. When registering, using, and managing an account, you should ensure the authenticity and accuracy of the information filled in when registering the account. Please use true, accurate, legal, and valid relevant qualification certification materials and necessary information (including email address, contact number, contact address, etc.) when registering and managing your account. The company has the right to review the registration information (including but not limited to qualification documents, etc.) submitted by you. If the materials you submit or the information you provide are inaccurate, untrue, or illegal, or the company has reason to suspect that it is false, untrue, or illegal, the company reserves the right to reject it. If you have provided relevant qualification documents on the relevant platforms owned by the company's affiliates or authorized to operate legally, and have been verified (including but not limited to third-party public verification, etc.), you understand and agree that the company has the right to retrieve related data. If there is no match, the company has the right to reject the review.
<br/>
<h3>
3. "Brandgogo" platform and related services</h3>
1. The platform provides technical services including task release and acceptance, task management, and service fee settlement (hereinafter collectively referred to as "services").
<br/>
<br/>
2. After you have successfully registered a platform account, you can log in to the "Brandgogo" platform.
<br/>
<br/>
(1) If you are a customer, after logging in successfully, you can view the information of service providers, optional talents, creators, and MCN talents on the platform, post the task after prepaying the corresponding service fee, and choose the appropriate one according to your own needs. Talents, creators, MCN talents, and/or "Brandgogo" service providers to cooperate. The service provider is an optional service. If you choose "Brandgogo" service provider-related services, you need to prepay the corresponding service fee according to the platform prompts.
<br/>
<br/>
(2) If you are a master, creator, MCN master, or MCN institution, after successful login, you can view the specific task requirements of video shooting, content production, webcasting, and other tasks released by customers on the platform (hereinafter referred to as "task requirements"). ), and choose whether to accept the task requirements according to your own needs.
<br/>
<br/>
3. If you are a customer after you have reached a cooperation intention with experts, creators, MCN experts, and/or service providers, you should promptly communicate with them the requirements for content service provision and standards in accordance with the platform's management regulations and process requirements, and promptly communicate with them. On the platform, the service results of talents, creators, MCN talents, and/or service providers at various stages are checked and accepted.
<br/>
<br/>
3.If you are a master, creator, MCN master, or MCN organization after you reach a cooperation intention with your client, you should promptly communicate with them in accordance with the platform's management regulations and process requirements for script creation, video shooting, content production, service provision and Standards, and other requirements, and upload the service results of each stage (script creation, video shooting, material production, webcasting, service delivery, post-production, etc.) on the platform in a timely manner. According to the client's entrustment, for the entrusted work results, unless otherwise stipulated by the task requirements or platform management, the client can: 
1) request to publish the video through your "Brandgogo" associated platform account (subject to the specific task description); 
2)Use the video clips of this cooperation for advertising on the "Brandgogo" associated platform; 
3)Based on the task type and attributes if the customer can download your work results on the platform after the task is completed, the customer can upload the work results. For commercial or non-commercial reasonable use; 
4)to use the work results according to the cooperation intention reached with the customer; after you have reached the cooperation intention with the customer, if you will also entrust or arrange for a third party to actually provide the service content, If the actual service provided by the aforementioned third party does not meet the customer's mission requirements or platform management regulations, or the third party is unable to provide the service, you are solely responsible for the corresponding breach of contract and compensation to the customer and the platform in accordance with this agreement.
<br/>
<br/>
5. After the customer has accepted the service according to the platform rules, the platform will settle the service fee with the service provider, MCN agency, and Daren (excluding MCN Daren) in accordance with this Agreement, the Service Provider Cooperation Contract, the specific task rules, and the MCN Institutional Cooperation Agreement. ; If you are an MCN expert, the platform will settle the service fees for the corresponding tasks to your MCN institution in accordance with the "Management Rules for the Commercialization of Certified MCN Companies" and the agreement signed with MCN.
<br/>
<br/>
6. If you are a customer, you understand and agree that:
You should follow the platform rules and procedures for task release, service achievement acceptance, and other operations. You shall be responsible for your actions and shall not deny the validity of your actions for any reason. You can contact service providers and MCN institutions (including all the masters of MCN institutions) / masters and creators online through the platform to communicate specific project needs. But you must not.
                        </div>
                    </div>
                    <div style={{marginTop:'45px', fontWeight:500, textAlign:'center', transform: 'scale(0.8)'}}>
                        <Checkbox checked={isCheckedR} onClick={()=>changeR(!isCheckedR)}>
                            <span style={{color:'#888888'}}>{textJson['我已仔细阅读以上合同内容，并同意签约']}</span>
                        </Checkbox>
                    </div>
                </div> : <div style={{width:'100%', height:'350px', textAlign:'center', paddingTop:'40px', margin:'0 auto', color:'#333333'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                        style={{width:'173px', marginTop:'40px', marginBottom:'10px'}} />
                    <div style={{width:'100%'}}>{intl.get('AccountRecharge')['恭喜您已添加完成，请耐心等待红人接受！']}
                        <br />{textJson['平台将在1-3个工作日内进行审核，请注意查看审核结果']}
                    </div>
                </div>
            }
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'26px'}}>
                {tabIndex>0&&tabIndex<3 ? <div onClick={()=>changeIndex(false)} style={{
                    width: '210px', height: '40px', border:'1px solid #F7385B',
                    textAlign: 'center', lineHeight: '40px', color: '#F7385B', borderRadius: '4px',
                    cursor: 'pointer', letterSpacing: '1px', marginRight:'14px'
                }}>{textJson['返回上一步']}</div>:''}
                {tabIndex<3 ? <Button onClick={()=>changeIndex(true)} disabled={!isNext()} style={{
                    width: '210px', height: '40px', background: !isNext()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)',
                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                }}>{tabIndex == 2 ? textJson['同意合同内容并签约入驻'] : textJson['下一步']}</Button>:
                <Button onClick={()=>{
                    props.close();
                    setTimeout(()=>{
                        window.location.reload()
                    },500)
                }} style={{
                    width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '1px', border:0
                }}>{intl.get('LivingDemand')['我知道啦']}</Button>}
            </div>
        </Modal>
    </div>
}
export default withRouter(BloggerSigning);