import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import StatusVideo from '../../../components/DemandHall/StatusVideo'
import StatusLive from '../../../components/DemandHall/StatusLive'
import '../../../asset/css/LivingDemand.scss'
import { Input, Modal, InputNumber, message, Checkbox, Spin, List, Divider, Skeleton, Drawer, Image, Radio } from 'antd';
import { DownSquareTwoTone, UpSquareTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import intl from 'react-intl-universal';
import * as Api from '../../../service/https';
import userEvent from '@testing-library/user-event'
import store from '../../../utils/redux/index'
import three from '../../../utils/three'
import {debounce} from '../../../utils/debounce'
import Enum from '../../../utils/Enum/Enum'

const DemandHall = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [textJsonL, setTextJsonL] = useState(intl.get('LivingDemand'))
    const [listParams, setListParams] = useState({
        target: -1,
        cooperationWay: -1,
        classification: -1,
        media: -1,
        orderWay: 4,
        sortWay: 2,
        sendSample: '',
        current: 1,
        size: 10,
    })
    const [isSuccess, setScucess] = useState(false)
    const [isApply, setApply] = useState(false)
    const [statusFList, setStatusFList] = useState([
        {
            id: -1,
            value: lang == 'zh' ?'全部':'All',
        },
        {
            id: 3,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[3].val]
        },
        // {
        //     id: 1,
        //     value: intl.get('ReleaseDemand')[Enum.propertiesTarget[1].val]
        // },
        {
            id: 2,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[2].val]
        },
        {
            id: 4,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[4].val]
        },
        {
            id: 5,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[5].val]
        },
        {
            id: 6,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[6].val]
        },
    ])
    const [statusList, setStatusList] = useState([{
        id: -1,
        value: lang == 'zh' ? '全部' : 'All'
    }, {
        id: 1,
        value: textJson['短视频']
    }, {
        id: 2,
        value: textJson['直播']
    }
    ])
    const [id, setID] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [params, setParams] = useState({})
    const [reload, setReload] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(state.userInfo){
            if(state.userInfo.userType == 2){
                setUserInfo(state.userInfo)
            }else{
                getMCNaddress(state.userInfo)
            }
        }else{
            getUserInfoFun()
        }
        getCargo()
    }, [])

    const getUserInfoFun = () => {
        Api.getUserInfo().then(res => {
            if (res.code === 200) {
                if(res.data.userType == 2){
                    setUserInfo(res.data)
                }else{
                    getMCNaddress(res.data)
                }
            }
        })
    }
    const getMCNaddress = item => {
        Api.getMCNaddress().then(res => {
            if (res.code === 200) {
                setParams({})
                let obj = item
                obj.redAccountDetail = res.data
                setUserInfo(obj)
            }
        })
    }

    useEffect(() => {
        if(userInfo?.userType) getMarketlist()
    }, [listParams,userInfo.userType])

    const listParams_onChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(listParams))
        if (id === 'orderWay') {
            t['current'] = 1
            if (e === t[id]) {
                t['sortWay'] = t['sortWay'] === 1 ? 2 : 1
            } else {
                t['sortWay'] = 2
            }
        }
        if (id === 'classification') {
            t['current'] = 1
        }
        if (id === 'target') {
            t['current'] = 1
        }
        if (id === 'cooperationWay') {
            t['current'] = 1
        }
        if (id === 'cooperationWay' && e === -1) {
            t['orderWay'] = 4
            t['sortWay'] = 2
        }
        t[id] = e
        setListParams(t)
    }

    const loadMoreData = e => {
        let t = JSON.parse(JSON.stringify(listParams))
        t.current++
        setListParams(t)
    }

    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])

    const getMarketlist = e => {
        setLoading(true)
        Api[userInfo?.userType == 2 ?  'getMarketlist':'getMcnMarket'](listParams).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let l = listParams.current === 1 ? res.data.demandInfos : [...list, ...res.data.demandInfos]
                setList(l);
            }
            setLoading(false)
        })
    }

    const [classList, setClassList] = useState([])
    const [classShow, setClassShow] = useState(false)

    const getCargo = () => {
        Api.getCargo().then(res => {
            if (res.code == 200) {
                let all = res.data
                all.unshift({ id: -1, desc: '全部', goodsEnglishName: 'All' })
                setClassList(all)
            }
        })
    }

    const postApply = (index) => {
        setApply(true)
    }

    const setIDFun = e => {
        setID(e)
    }

    const [isAddress, setIsAddress] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [addressIndex, setAddressIndex] = useState(-1)
    const getCurrentUserAddressList = ()=>{
        Api.getCurrentUserAddressList().then(res=>{
            if(res.code == 200){
                setAddressList(res.data)
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    if(element.defaultAddress){
                        setAddressIndex(index)
                        break
                    }
                }
            }
        })
    }
    useEffect(()=>{
        if(isApply){
            getCurrentUserAddressList()
        }
    },[isApply])
    const [addParams, setAddParams] = useState({})
    const addChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(addParams))
        t[id] = e
        setAddParams(t)
    }
    useEffect(()=>{
        if(addressIndex > -1){
            let obj = {
                cost:0,
                consigneeSpecificAddress: addressList[addressIndex].address , 
                consigneePostCode: addressList[addressIndex].postCode, 
                consigneePhone: addressList[addressIndex].phone, 
                consigneeName: addressList[addressIndex].contactName, 
            }
            setParams(obj)
        }
    },[addressIndex])
    const addAddress = ()=>{
        if (JSON.stringify(addParams).indexOf(('""')) === -1 && JSON.stringify(addParams) !== '{}') {
            let obj = {
                uid: state.userInfo?.uid, address: addParams.consigneeSpecificAddress, 
                postCode: addParams.consigneePostCode, phone: addParams.consigneePhone, 
                contactName: addParams.consigneeName, defaultAddress: true 
            }
            // console.log(obj)
            Api.addAddress(obj).then(res=>{
                if(res.code == 200){
                    getCurrentUserAddressList()
                    setIsAddress(false)
                    setAddParams({})
                }
            })
        }else{
            message.warn("内容未填写完整！")
        }
    }

    const submit = () => {
        if(state.userInfo?.userType == 3) {
            if(!bloggerItem){
                message.warn("内容未填写完整！")
                return
            }
        }
        if(id.cooperationWay == 1){
            if (id.sendSample === 1 && id.asettleWay === 1) {
                params.costDollar = 0
            } else {
                params.costDollar = Math.floor(id.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0)
            }
        }else{
            if (id.sendSample === 1 && id.asettleWay === 1) {
                params.costDollar = 0
            } else {
                params.costDollar = Math.floor(id.durationH) * (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0)
            }
        }
        if ((JSON.stringify(params).indexOf(('""')) === -1 && JSON.stringify(params) !== '{}' && addressIndex > -1) || id.sendSample != 1) {
            let b = {
                target: id.target,
                media: id.media,
                cooperationWay: id.cooperationWay,
            }
            if(state.userInfo?.userType == 3 && bloggerItem) b.userIdBlogger = bloggerItem.uid
            let data = JSON.parse((JSON.stringify(id) + JSON.stringify(b)).replace(/}{/, ','));
            data = JSON.parse((JSON.stringify(data) + JSON.stringify(params)).replace(/}{/, ','));
            console.log(data)
            Api[state.userInfo?.userType == 2?'marketApply':'marketMcnApply'](data).then(res => {
                if (res.code === 200) {
                    setApply(false);
                    setScucess(true);
                    let t = reload + 1
                    setReload(t)
                    setBloggerItem(null)
                    setAddressIndex(-1)
                }
            })
        }
        else
            message.warn("内容未填写完整！")
    }

    const [videoVisible, setVideoVisible] = useState(false)
    const [url, setUrl] = useState()

    const playVideo = e => {
        setVideoVisible(true)
        setUrl(e)
    }




    const [isHotR, setIsHotR] = useState(false)
    const [bloggerItem, setBloggerItem] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const [searchList, setSearchList] = useState([])
    const handleSearch = (value) => {
        setValueSearch(value==''?null:value)
    }
    
    useEffect(()=>{
        if (valueSearch) {
            getSearch()
        } else {
            setSearchList([])
        }
    }, [valueSearch])
    const getSearch = () => {
        setFetching(true)
        let obj = {
            cargoClassification: "",
            classify: [],
            current: 1,
            filterName: valueSearch,
            nationality: "",
            redAccountSortRuler: 1,
            size: 200
        }
        Api.getRedAccount(obj).then(res=>{
            setFetching(false)
            if(res.code == 200){
                setSearchList(res.data.records)
            }
        })
    }
    const addBloggerS = (item,index) => {
        setBloggerItem(item)
        setIsHotR(false)
    }


    useEffect(()=>{
        if(!isApply){
            setBloggerItem(null)
        }
    },[isApply])
    useEffect(()=>{
        if(!isHotR){
            setValueSearch(null)
        }
    },[isHotR])




    return (
        <div id='Status'>

            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>{textJson['需求大厅']}</div>
            </div>

            <Spin spinning={loading}>
                <div style={{
                    width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '23px',
                    fontWeight: 500, marginTop: '10px'
                }}>

                    {/* 所属行业 */}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <label style={{ width: state.language == 'zh' ? '102px' : '145px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('ReleaseDemand')['所属行业']}：</label>
                        <div style={{
                            width: 'calc(100% - 70px)', height: classShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                            overflow: classShow ? '' : 'hidden'
                        }}>
                            {
                                classList?.map((item, index) => {
                                    return <span key={item.id} className='tabItem' style={{
                                        padding: '6px 10px',
                                        background: item.id == listParams.classification ? '#FFE7E9' : 'none', marginBottom: '5px',
                                        color: item.id == listParams.classification ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => listParams_onChange(item.id, 'classification')}>{lang == 'zh' ? item.desc : item.goodsEnglishName ? item.goodsEnglishName : item.desc}</span>
                                })
                            }
                        </div>
                        <div style={{ width: '45px' }}>
                            {!classShow ?
                                <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                    onClick={() => { setClassShow(true) }} />
                                :
                                <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                    onClick={() => setClassShow(false)} />}
                        </div>
                    </div>

                    {/* 需求目标 */}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <label style={{ width: state.language == 'zh' ? '99px' : '145px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('ReleaseDemand')['需求目标']}：</label>
                        <div style={{
                            width: 'calc(100% - 70px)', height: '39px', display: 'flex'
                        }}>
                            {
                                statusFList?.map((item, index) => {
                                    return <span key={index} className='tabItem' style={{
                                        padding: '6px 10px',
                                        background: listParams.target == item.id ? '#FFE7E9' : 'none', marginBottom: '5px',
                                        color: listParams.target == item.id ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => listParams_onChange(item.id, 'target')}>{item.value}</span>
                                })
                            }
                        </div>
                    </div>

                    {/* 内容形式 */}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <label style={{ width: state.language == 'zh' ? '99px' : '145px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('ReleaseDemand')['内容形式']}：</label>
                        <div style={{
                            width: 'calc(100% - 70px)', height: '39px', display: 'flex'
                        }}>
                            {
                                statusList?.map((item, index) => {
                                    return <span key={index} className='tabItem' style={{
                                        padding: '6px 10px',
                                        background: listParams.cooperationWay == item.id ? '#FFE7E9' : 'none', marginBottom: '5px',
                                        color: listParams.cooperationWay == item.id ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => listParams_onChange(item.id, 'cooperationWay')}>{item.value}</span>
                                })
                            }
                        </div>
                    </div>

                </div>

                <div style={{ backgroundColor: '#fff', height: '56px', padding: '0px 30px', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>

                    <div style={{ display: 'flex' }}>

                        <div style={{
                            color: listParams.orderWay === 4 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px'
                        }}
                            onClick={e => listParams_onChange(4, 'orderWay')}>{intl.get('ReleaseDemand')['需求有效日期']}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 4 && listParams.sortWay == 1 ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 4 && listParams.sortWay == 2 ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '2px' }} />
                        </div>
                        {
                            listParams.cooperationWay !== -1 ?
                                <div style={{ color: listParams.orderWay === 2 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px' }} onClick={e => listParams_onChange(2, 'orderWay')}>{listParams.cooperationWay === 1 ? intl.get('ReleaseDemand')['红人截稿日期'] : intl.get('ReleaseDemand')['红人直播日期']}
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 2 && listParams.sortWay == 1 ? 's' : '') + '.png'} alt=""
                                        style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 2 && listParams.sortWay == 2 ? 's' : '') + '.png'} alt=""
                                        style={{ marginLeft: '2px' }} />
                                </div>
                                :
                                ''
                        }
                        {
                            listParams.cooperationWay !== -1 ?
                                <div style={{
                                    color: listParams.orderWay === 3 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px'
                                }}
                                    onClick={e => listParams_onChange(3, 'orderWay')}>{intl.get('ReleaseDemand')['时长']}
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 3 && listParams.sortWay == 1 ? 's' : '') + '.png'} alt=""
                                        style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (listParams.orderWay === 3 && listParams.sortWay == 2 ? 's' : '') + '.png'} alt=""
                                        style={{ marginLeft: '2px' }} />
                                </div>
                                :
                                ''
                        }
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '50px' }}>
                            <Checkbox className='active' checked={listParams.sendSample === 1} onChange={(e) => {
                                if (e.target.checked) {
                                    listParams_onChange(1, 'sendSample')
                                } else {
                                    listParams_onChange('', 'sendSample')
                                }
                            }}>
                                <span style={{ color: listParams.sendSample === 1 ? '#F7385B' : '' }}>{intl.get('LivingDemand')['支持样品寄送']}</span>
                            </Checkbox>
                        </div>
                        <div style={{}}>
                            <Checkbox className='active' checked={listParams.sendSample === 2} onChange={(e) => {
                                if (e.target.checked) {
                                    listParams_onChange(2, 'sendSample')
                                } else {
                                    listParams_onChange('', 'sendSample')
                                }
                            }}>
                                <span style={{ color: listParams.sendSample === 2 ? '#F7385B' : '' }}>{intl.get('LivingDemand')['不支持样品寄送']}</span>
                            </Checkbox>
                        </div>
                    </div>

                </div>

                <InfiniteScroll
                    dataLength={list.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={total > list.length}
                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={total === list.length && list.length > 0 ? <Divider plain>{intl.get('TopicList').Nothing} 🤐</Divider> : ''}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={list}
                        grid={{ gutter: 0, column: 1 }}
                        locale={{
                            emptyText:
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '135px' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 166, height: 159 }} alt="" />
                                    <div style={{ width: '242px', textAlign: 'center', marginTop: '13px' }}>{intl.get('DemandHall')['抱歉，当前没有待合作的需求。']}</div>
                                </div>
                        }}
                        renderItem={
                            (item, index) => list.length > 0 ?
                                item?.cooperationWay === 1 ?
                                    <StatusVideo item={item} postApply={postApply} setID={setIDFun} playVideo={playVideo} />
                                    :
                                    <StatusLive item={item} postApply={postApply} setID={setIDFun} playVideo={playVideo} />
                                : ''
                        }
                        rowKey={(item, index) => index}
                    />
                </InfiniteScroll>

            </Spin>

            <Modal centered visible={isSuccess} footer={null} onCancel={e => { setScucess(false) }}
                getContainer={() => document.getElementById('Status')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 443, height: 466,
                }}
            >
                <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['申请合作发布成功']}</h3>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                        style={{ width: '173px', marginTop: '40px' }} />
                    <div>{textJson['你的合作申请已送达至商家，请耐心等待商家的结果']}</div>
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => window.open(id.cooperationWay == 1?`/index/videoDemand`:'/index/LivingDemand')} >
                        {textJson['前往我申请的合作']}
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                visible={isApply}
                footer={null}
                onCancel={e => {
                    setApply(false);
                    setParams({})
                }}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
                getContainer={() => document.getElementById('Status')}
            >
                <div style={{ textAlign: 'center', padding: '57px 49px 64px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['申请与商家合作']}</h3>
                    {state.userInfo?.userType == 3 ? <div style={{display:'flex', alignItems:'center', marginTop:'30px'}}>
                        <span style={{color: '#666666',}}>{intl.get('ReleaseDemand')['指定红人']}</span>
                        {bloggerItem ? <div style={{ display: 'flex', marginLeft:'10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '44px', height: '44px', overflow: 'hidden', borderRadius: '22px' }}>
                                <Image src={bloggerItem?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={44} height={44} style={{ borderRadius: '22px'}} preview={false} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='over1' style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{bloggerItem?.nickname}</div>
                                </div>
                                <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {bloggerItem?.tiktok}</div>
                            </div>
                        </div>:''}
                        <div style={{
                            width: '86px', height: '30px', borderRadius: '4px', cursor: 'pointer', fontSize:'12px',
                            textAlign: 'center', lineHeight: '30px', color: '#FFF', marginLeft:'20px',
                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                        }}
                            onClick={() => {getSearch(); setIsHotR(true)}} >
                            {bloggerItem?intl.get('ReleaseDemand')['更换红人']:intl.get('AccountRecharge')['选择红人']}
                        </div>
                    </div>:''}
                    <div style={{
                        color: '#666666',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: id.sendSample === 1 || state.userInfo?.userType == 3 ? 'flex-start' : 'center',
                        marginTop: state.userInfo?.userType == 3?'30px':'53px',
                        fontWeight: 500
                    }}>
                        <div>{textJson['总计佣金']}</div>
                        {
                            id.sendSample === 1 && id.asettleWay === 1 ?
                                <div className='flex-ac'>
                                    <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                        $0
                                    </div>
                                    <div style={{ color: '#F7385B', fontSize: '14px', marginLeft: '10px' }}>({textJsonL['寄样抵佣金']})</div>
                                    <div style={{ color: '#888888', marginLeft: '8px' }}>
                                        {
                                            id.cooperationWay === 1 ?
                                                `(${intl.get('VideoDetail')['视频时长']}${store.getState().language === 'en' ? ' in' + id.duration + 's' : id.duration + 's内'})`
                                                :
                                                `(${textJsonL['直播时长']}${store.getState().language === 'en' ? ' in' + id.durationH + 'h' : id.durationH + 'h内'})`
                                        }
                                    </div>
                                </div>
                                :
                                <div className='flex-ac'>
                                    <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                        {
                                            id.cooperationWay === 1 ?
                                                `$${Math.floor(id.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0)}`
                                                :
                                                `$${Math.floor(id.durationH) * (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0)}`
                                        }
                                    </div>
                                    <div style={{ color: '#888888', marginLeft: '8px' }}>
                                        {
                                            id.cooperationWay === 1 ?
                                                `${textJsonL['红人视频单价']}${store.getState().language === 'en' ? ' in $' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/60s' : '$' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/' + intl.get('NewLogin')['60s内']
                                                } * ${intl.get('VideoDetail')['视频时长']}${store.getState().language === 'en' ? ' in' + id.duration + 's' : id.duration + 's内'
                                                }`
                                                :
                                                `${textJsonL['红人直播单价']}${store.getState().language === 'en' ? ' in $' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/1h' : '$' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/' + intl.get('NewLogin')['1h内']} * ${textJsonL['直播时长']}${store.getState().language === 'en' ? ' in' + id.durationH + 'h' : id.durationH + 'h内'
                                                }`
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                    {
                        id.sendSample === 1 ?
                            <div>
                                <div style={{
                                    color: '#666666',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '33px',
                                    fontWeight: 500
                                }}>
                                    {textJson['样品收件信息']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['商家提供样品，请正确输入你的收件信息']}）</span>
                                    <div style={{
                                    width: '88px', height: '30px', borderRadius: '4px', cursor: 'pointer',
                                    textAlign: 'center', lineHeight: '30px', color: '#F7385B',
                                    border:'1px solid #F7385B', fontWeight: '500', fontSize:'13px'
                                }}
                                    onClick={() => setIsAddress(true)} >
                                    {intl.get('SmallShop')['添加地址']}
                                </div>
                            </div>
                            {
                                addressList?.map((item,index)=>{
                                    return <div key={index} style={{width:'100%', display:'flex', alignItems:'center', marginTop:'17px', textAlign:'left', marginBottom:'10px'}}>
                                    <div style={{width:'100%', display:'flex', alignItems:'center', height:'78px', border: '1px solid #E2E2E2', borderRadius:'4px', borderLeft:0,
                                    }}>
                                        <img style={{height:'100%'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/address.png" />
                                        <div style={{width:'100%', marginLeft:'29px'}}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <div style={{width:'40%'}}>N : {item.contactName}</div><div>T : {item.phone}</div>
                                            </div>
                                            <div>{item.address}, {item.postCode}</div>
                                        </div>
                                    </div>
                                    <Radio checked={addressIndex == index} 
                                        onChange={e=>setAddressIndex(index)} style={{marginLeft:'19px'}} />
                                </div>
                                })
                            }
                            </div>
                            :
                            ''
                    }
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '0px auto', marginTop: '47px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => submit()} >
                        {textJson['确定申请']}
                    </div>
                    <div style={{ fontSize: '12px', color: '#888888', marginTop: '12px' }}>
                        {textJsonL['温馨提示：当商家同意与你合作，请准时在截稿日期前完成合作，过期没有上传将会扣除平台信用分']}
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                visible={isAddress}
                footer={null}
                onCancel={e => {
                    setIsAddress(false);
                    setAddParams({})
                }}
                getContainer={() => document.getElementById('Status')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
            >
                <div id='login' style={{ textAlign: 'center', padding: '50px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom:'30px' }}>{intl.get('SmallShop')['添加地址']}</h3>
                    <div>
                        <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Input value={addParams.consigneeName} onChange={e => addChange(e.target.value, 'consigneeName')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入收件人姓名']} />
                            <Input value={addParams.consigneePhone} onChange={e => addChange(e.target.value, 'consigneePhone')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入收件人手机号']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneePostCode} onChange={e => addChange(e.target.value, 'consigneePostCode')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入邮编']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneeSpecificAddress} onChange={e => addChange(e.target.value, 'consigneeSpecificAddress')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入具体的街道门牌']} />
                        </div>
                    </div>
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto', marginBottom: '12px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => addAddress()} >
                        {intl.get('SmallShop')['确定添加']}
                    </div>
                </div>
            </Modal>  

            <Modal visible={videoVisible} centered={true} footer={null} onCancel={e => { setVideoVisible(false) }} destroyOnClose={true}
                getContainer={() => document.getElementById('Status')}>
                <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <video width="800" height="500" controls>
                        <source src={url} type="video/mp4" />
                        <source src={url} type="video/ogg" />
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
            </Modal>







            <Drawer placement="right" visible={isHotR} closable={false} width={550} style={{zIndex:10000}}>
                <div id='login' style={{padding:'30px 10px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <span style={{fontSize:'18px', color:'#333'}}>{textJson['指定红人']}</span>
                        <CloseCircleOutlined onClick={()=>setIsHotR(false)}
                            style={{color:'#888888', fontWeight:700, fontSize:'20px', cursor:'pointer'}} />
                    </div>     
                    <div style={{width:'345px', display:'flex', margin:'0 auto', marginTop:'70px'}}>
                        <Input onChange={e=>handleSearch(e.target.value)} value={valueSearch}
                            style={{width:'294px', height:'48px', borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px'}} 
                            placeholder={intl.get('AccountRecharge')['请输入机构下红人昵称或账号搜索']} />
                        <div style={{width:'50px', height:'48px', borderTopRightRadius:'4px', borderBottomRightRadius:'4px', cursor:'pointer',
                            textAlign:'center', lineHeight:'45px', color: '#FFF',
                            background: '#F7385B', fontWeight:'blod' }} >
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sousuo.png'} style={{width:'16px'}} alt="" />
                        </div>
                    </div>    
                    <Spin spinning={fetching}>
                    {searchList.length > 0 ? <div style={{width:'100%', marginTop:'25px' }}>
                        {
                            searchList.map((item,index)=>{
                                return <div key={index} style={{width:'100%', height:'85px', display:'flex', alignItems:'center', justifyContent:'space-between'}}
                                >
                                    <Image src={item.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                    style={{ width: '44px', height: '44px', borderRadius: '22px' }} />
                                    <div style={{width:'calc(100% - 54px)', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between',
                                        borderBottom:'1px dashed #CCCCCC'}}>
                                        <div style={{width:'160px'}}>
                                            <div className='over1' style={{fontWeight:600, color:'#333'}}>{item.nickname}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-4px'}}>TikTok: {item.tiktok}</div>
                                        </div>  
                                        {id.cooperationWay === 1 ? <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.videoQuotationDollar)}/{intl.get('NewLogin')['60s内']}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['视频制作单价']}</div>
                                        </div>:
                                            <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.liveBroadcastQuotationDollar)}/{intl.get('NewLogin')['1h内']}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['直播制作单价']}</div>
                                        </div>}
                                        <div onClick={()=>addBloggerS(item,index)} style={{width:'85px', height:'28px', borderRadius:'4px', cursor:'pointer',
                                            textAlign:'center', lineHeight:'28px', color: '#FFF', fontSize:'12px',
                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                            {intl.get('AccountRecharge')['确认选择']}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div> :          
                    <div style={{width:'345px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                        <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{intl.get('ReleaseDemand')['暂无数据，请搜索添加']}</div>
                    </div>  }        
                    </Spin>           
                </div>
            </Drawer>  

        </div>
    )
}
export default withRouter(DemandHall);